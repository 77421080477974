import {CommonTypesAccessControlCondition} from '@bitkey-service/v2_core-types/lib/common/commonTypesAccessControl';
import {V2StoreTypesOrgNfcGroup} from '@bitkey-service/v2_core-types/lib/store/organizations/nfc-groups/v2_storeTypesOrgNfcGroup';
import {V2StoreTypesOrgSpace} from '@bitkey-service/v2_core-types/lib/store/organizations/space/v2_storeTypesSpace';
import {V2StoreTypesOrgThing} from '@bitkey-service/v2_core-types/lib/store/organizations/things/v2_storeTypesOrgThing';
import {UserGroupType} from '@bitkey-service/v2_core-types/lib/store/organizations/user-groups/v2_storeTypesOrgUserGroup';
import styled from '@emotion/styled';
import {Timestamp} from '@google-cloud/firestore';
import {createFileRoute} from '@tanstack/react-router';
import dayjs from 'dayjs';
import {useCallback, useEffect, useMemo, useState} from 'react';
import type {SetRequired} from 'type-fest';
import {useQuery} from 'urql';
import * as z from 'zod';

import {getNfcCardAccessControlsApi} from '@/api-call/workhub-core/getNfcCardAccessControlsApi';
import {ActivationGroup} from '@/common/feature-control/featureDefinitions';
import {FirestoreAccessControls} from '@/common/firebase/firestore/references/firestoreOrgAccessControls';
import {FirestoreAccessControlTargetPatterns} from '@/common/firebase/firestore/references/firestoreOrgAccessControlTargetPatterns';
import {FirestoreOrgContractMembers} from '@/common/firebase/firestore/references/firestoreOrgContractMembers';
import {
  ALIVE_CONTRACT_STATUS,
  FirestoreOrgContracts,
} from '@/common/firebase/firestore/references/firestoreOrgContracts';
import {FirestoreOrgNfcGroups} from '@/common/firebase/firestore/references/firestoreOrgNfcGroups';
import {FirestoreOrgSpaces} from '@/common/firebase/firestore/references/firestoreOrgSpaces';
import {FirestoreOrgThings} from '@/common/firebase/firestore/references/firestoreOrgThings';
import useDict, {useCommonDict} from '@/common/hooks/useDict';
import {useExperimentalFeature} from '@/common/hooks/useExperimentalFeature';
import {useLoginUser} from '@/common/hooks/useLoginUser';
import {withSearchState} from '@/common/hooks/useSearchState';
import {useSnackbar} from '@/common/hooks/useSnackbar';
import {Locale} from '@/common/redux/state-types/localeStateType';
import ArrayUtil from '@/common/utils/arrayUtil';
import {TimeUtils} from '@/common/utils/timeUtils';
import WAlert from '@/components/alert/WAlert';
import WHeaderTab2 from '@/components/figma/header/WHeaderTab2';
import WLoadingComponent from '@/components/figma/others/stepper/WLoadingComponent';
import WHeaderNavigation, {PropBreadcrumb, WHeaderActions} from '@/components/header/WHeaderNavigation';
import {WNfcCardLockUnlockLogCsvDownloadDialog} from '@/features/nfc-card/table/WNfcCardLockUnlockLogCsvDownloadDialog';
import {WNfcCardLockUnlockLogTable} from '@/features/nfc-card/table/WNfcCardLockUnlockLogTable';
import WNfcCardOverview from '@/features/nfc-card/tabs/WNfcCardOverview';
import {graphql} from '@/gql';
import {V2NfcService} from '@/v2_service/nfcCards/V2_nfcService';
import {useFetchNfcCard} from '@/wcustomhooks/nfc-card/useFetchNfcCard';

const tabIds = ['overview', 'log'] as const;

type HeaderTabId = (typeof tabIds)[number];

const searchSchema = z.object({
  tabId: z.enum(tabIds).catch('overview'),
  search: z.string().optional(), // 一覧画面のパラメータを持っておく
});

const {validateSearch, useSearchState} = withSearchState({
  schema: searchSchema,
});

export const Route = createFileRoute('/_authorized/nfc-cards/$nfcCardId')({
  component: RouteComponent,
  validateSearch,
});

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const dictDef = {
  overView: {
    default: {
      default: '概要',
      [Locale.en_US]: 'Overview',
    },
  },
  log: {
    default: {
      default: '利用ログ',
      [Locale.en_US]: 'Log',
    },
  },
  securityCardManagement: {
    default: {
      default: 'セキュリティカード管理',
      [Locale.en_US]: 'Security Card Management',
    },
  },
  securityCards: {
    default: {
      default: 'セキュリティカード',
      [Locale.en_US]: 'Security Cards',
    },
  },
  successDeleteMessage: {
    default: {
      default: '削除が完了しました',
      [Locale.en_US]: 'Succeeded in delete.',
    },
  },
  failedDeleteMessage: {
    default: {
      default: '削除に失敗しました',
      [Locale.en_US]: 'Failed in delete.',
    },
  },
  processingDeleteMessage: {
    default: {
      default: 'データを削除しています',
      [Locale.en_US]: 'Deleting data...',
    },
  },
  deleteMessageTitle: {
    default: {
      default: 'セキュリティカードの削除',
      [Locale.en_US]: 'Delete Security Card',
    },
  },
  loading: {
    default: {
      default: 'セキュリティカード情報を取得中です...',
      [Locale.en_US]: 'Loading...',
    },
  },
  deleteMessage: {
    default: {
      default:
        'セキュリティカードを削除すると、そのセキュリティカードの解錠権限も削除されます。\n本当に削除してよろしいですか？',
      [Locale.en_US]: 'If you delete a nfc-card, it will no longer be available. \nAre you sure you want to delete it?',
    },
  },
  csvDownload: {
    default: {
      default: 'CSV出力',
      [Locale.en_US]: 'CSV Download',
    },
  },
};

const personQuery = graphql(`
  query GetNfcCardPerson($id: String!) {
    person(id: $id) {
      id
      iconImage
      nameJp
      nameEn
      email
      code
      type
      superUser
      familyNameJp
      firstNameJp
      familyNameEn
      firstNameEn
      faceRegistered
      employeeStatus
      modifiedAt
      userGroups {
        id
        type
        nameJp
        nameEn
      }
    }
  }
`);

const usePersonQuery = (args: {personId: string}) => {
  const {personId} = args;
  const [result] = useQuery({
    query: personQuery,
    variables: {
      id: personId,
    },
    pause: !personId, // personIdが空だったりしたらクエリを一時停止
    requestPolicy: 'cache-and-network',
  });

  const person = useMemo(() => {
    if (!personId || !result.data) {
      return undefined;
    }
    return result.data.person;
  }, [personId, result.data]);

  return {
    person,
  };
};

function RouteComponent() {
  const {nfcCardId} = Route.useParams();
  const navigate = Route.useNavigate();

  const dict = useDict(dictDef);
  const commonDict = useCommonDict();
  const user = useLoginUser();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const openDeleteDialog = useCallback(() => setDeleteDialogOpen(true), []);
  const closeDeleteDialog = useCallback(() => setDeleteDialogOpen(false), []);

  const {
    data: nfcCard,
    isLoading,
    refetch: refetchNfcCard,
  } = useFetchNfcCard({organizationId: user.organizationId, nfcCardId});

  const [accessibleThings, setAccessibleThings] = useState<
    {
      thing?: V2StoreTypesOrgThing;
      space?: V2StoreTypesOrgSpace;
      conditions?: (CommonTypesAccessControlCondition & {
        accessControlName: string;
      })[];
    }[]
  >();

  const [nfcGroup, setNfcGroup] = useState<V2StoreTypesOrgNfcGroup>();

  const personId = useMemo(() => {
    if (!nfcCard) {
      return '';
    }
    return !nfcCard.lendingPersonaId ? (nfcCard.peopleId ?? '') : nfcCard.lendingPersonaId;
  }, [nfcCard]);
  const {person} = usePersonQuery({personId});
  const department = useMemo(
    () => person?.userGroups.find(ug => ug.type === UserGroupType.Department),
    [person?.userGroups]
  );
  const office = useMemo(() => person?.userGroups.find(ug => ug.type === UserGroupType.Office), [person?.userGroups]);

  const loadAccessControlsV1 = useCallback(
    async (target: {peopleId: string; userGroupIds: string[]}) => {
      const accessControl = await Promise.all([
        ...(await FirestoreAccessControls.getByPeopleId(user.organizationId, target.peopleId)),
        ...(await FirestoreAccessControls.getByUserGroupIds(user.organizationId, target.userGroupIds)),
      ]);
      // その人がどの契約に紐づいているかを検索している、この処理自体はThirdPlace利用していないと不要なのでこのような処理にしてます。

      if (user.activations.some(a => a.activationGroup === ('thirdPlace' as ActivationGroup))) {
        const customerContracts = (
          await FirestoreOrgContracts.getByCustomerIds(user.organizationId, target.userGroupIds)
        ).filter(
          contract =>
            ALIVE_CONTRACT_STATUS.includes(contract.status) &&
            // 未入力の場合は100年後なので終了日はどんな時でも入ってるが、、
            // 不正データが怖いので一応、、
            (!contract.contractTerm.endAt || dayjs(contract.contractTerm.endAt).isSameOrAfter(dayjs(), 'd'))
        );

        const contractMemberIncludeTarget = await Promise.all(
          customerContracts.map(async contract => {
            const contractMembers = await FirestoreOrgContractMembers.getById(
              user.organizationId,
              contract.id,
              target.peopleId
            );
            return {
              contract,
              isContractMember: !!contractMembers,
            };
          })
        );
        const contracts = contractMemberIncludeTarget
          .filter(contractMember => contractMember.isContractMember)
          .map(contractMember => contractMember.contract);
        const contractAccessControl = await FirestoreAccessControls.getByContractIds(
          user.organizationId,
          contracts.map(c => c.id)
        );
        accessControl.push(...contractAccessControl);
      }

      const now = Date.now();
      return accessControl.filter(a => {
        if (!a.unsyncedCondition?.epochTo) {
          return true;
        }
        if (typeof a.unsyncedCondition?.epochTo === 'number') {
          return a.unsyncedCondition?.epochTo && now < a.unsyncedCondition?.epochTo;
        }
        // 型を無視してepochToにTimestampがぶちこまれている時があるので、
        // その場合も一応フォローする。
        return a.unsyncedCondition?.epochTo && now < (a.unsyncedCondition?.epochTo as Timestamp).toMillis();
      });
    },
    [user.organizationId, user.activations]
  );

  /** ACv2 を使ってセキュリティカードが権限を持つ AC の一覧を取得する. */
  const loadAccessControlsV2 = useCallback(async () => {
    const {data} = await getNfcCardAccessControlsApi({paths: {nfcCardId}});
    return data;
  }, [nfcCardId]);

  const {enabled: accessControlCriteriaEnabled} = useExperimentalFeature('accessControlCriteria');

  const loadAccessThings = useCallback(
    async ({
      target,
    }: {
      target: {peopleId: string; userGroupIds: string[]};
    }): Promise<
      {
        thing?: V2StoreTypesOrgThing;
        space?: V2StoreTypesOrgSpace;
        conditions?: (CommonTypesAccessControlCondition & {
          accessControlName: string;
        })[];
      }[]
    > => {
      const accessControls = accessControlCriteriaEnabled
        ? await loadAccessControlsV2()
        : await loadAccessControlsV1(target);

      const allTargetPatternIds = ArrayUtil.removeDuplicateBySet(accessControls.flatMap(c => c.targetPatternIds));
      const targetPatterns = await FirestoreAccessControlTargetPatterns.getByIds(
        user.organizationId,
        allTargetPatternIds
      );
      const thingIds = ArrayUtil.removeDuplicateBySet(
        targetPatterns.flatMap(pattern => pattern.lockDeviceIds).filter((id): id is string => !!id)
      );
      const allThings = await FirestoreOrgThings.getByIds(user.organizationId, thingIds);
      const spaceIds = ArrayUtil.removeDuplicateBySet(
        allThings.flatMap(t => t.spaceId).filter((id): id is string => !!id)
      );
      const allSpaces = await FirestoreOrgSpaces.getByIds(user.organizationId, spaceIds);

      const res = accessControls
        .map(a => {
          const currentTargetPatterns = targetPatterns.filter(t => a.targetPatternIds.includes(t.id));
          const thingIds = currentTargetPatterns
            .flatMap(pattern => pattern.lockDeviceIds)
            .filter((id): id is string => !!id);
          const things = allThings.filter(t => thingIds.includes(t.id));
          return things.map(thing => {
            const space = allSpaces.find(s => s.id === thing.spaceId);
            return {
              thing: thing,
              space: space,
              condition: a.unsyncedCondition,
              accessControlName:
                a.createdBy === 'Reservation'
                  ? `予約利用: ${TimeUtils.toString(a.unsyncedCondition?.epochFrom)} ~ `
                  : a.nameJp,
            };
          });
        })
        .flat();

      const response = res.reduce<
        {
          thing: V2StoreTypesOrgThing;
          space?: V2StoreTypesOrgSpace;
          conditions?: (CommonTypesAccessControlCondition & {
            accessControlName: string;
          })[];
        }[]
      >((array, current) => {
        if (!array.some(a => a.thing.id === current.thing.id)) {
          array.push({
            ...current,
            conditions: current.condition && [
              {
                ...current.condition,
                accessControlName: current.accessControlName,
              },
            ],
          });
        }
        array.map(a => {
          if (a.thing.id === current.thing.id) {
            if (!a.conditions?.[0]) {
              return a;
            }
            if (
              current.condition &&
              !a.conditions?.some(c => {
                return (
                  c.epochTo === current.condition?.epochTo &&
                  c.epochFrom === current.condition?.epochFrom &&
                  c.weeklyRepeatCondition === current.condition?.weeklyRepeatCondition &&
                  c.repeatCondition === current.condition?.repeatCondition &&
                  c.timezoneFrom === current.condition?.timezoneFrom &&
                  c.timeZoneType === current.condition?.timeZoneType
                );
              })
            ) {
              a.conditions?.push({
                ...current.condition,
                accessControlName: current.accessControlName,
              });
            }

            return {
              ...a,
              conditions: a.conditions,
            };
          } else {
            return a;
          }
        });
        return array;
      }, []);
      return response.map(r => ({
        ...r,
        conditions: r.conditions?.sort((prev, current) => {
          if (!prev.weeklyRepeatCondition) {
            return -1;
          }
          const prevFrom =
            prev.epochFrom && typeof prev.epochFrom === 'number'
              ? prev.epochFrom
              : // @ts-ignore
                (prev.epochFrom as Timestamp)?.toMillis();

          const currentFrom =
            current.epochFrom && typeof current.epochFrom === 'number'
              ? current.epochFrom
              : // @ts-ignore
                (current.epochFrom as Timestamp)?.toMillis();

          return prevFrom - currentFrom;
        }),
      }));
    },
    [user.organizationId, accessControlCriteriaEnabled, loadAccessControlsV1, loadAccessControlsV2]
  );

  const [deleteProcessing, setDeleteProcessing] = useState<boolean>(false);
  const snackbar = useSnackbar();
  const onDelete = useCallback(async () => {
    try {
      setDeleteProcessing(true);
      await V2NfcService.deleteById(nfcCardId);
      snackbar.success(dict.successDeleteMessage);
    } catch {
      setDeleteProcessing(false);
      snackbar.fail(dict.failedDeleteMessage);
    }
    setDeleteProcessing(false);
    setTimeout(() => {
      navigate({to: '/nfc-cards'});
    }, 1000);
  }, [nfcCardId, snackbar, dict.successDeleteMessage, dict.failedDeleteMessage, navigate]);

  const [{tabId, search}, setSearchState] = useSearchState(Route);
  const headerTabs = useMemo<{id: HeaderTabId; label: string}[]>(
    () => [
      {id: 'overview', label: dict.overView},
      {id: 'log', label: dict.log},
    ],
    [dict.log, dict.overView]
  );

  const onChangeHeaderTab = useCallback(
    (input: string) => {
      const tabId = input as HeaderTabId;
      setSearchState(state => ({...state, tabId}));
    },
    [setSearchState]
  );

  useEffect(() => {
    if (person) {
      // peopleがいた場合に、アクセス可能なカギ束情報を取得する。
      // こいつだけ処理やたら多いのでまたない。
      loadAccessThings({
        target: {
          peopleId: person.id,
          userGroupIds: person.userGroups.map(ug => ug.id),
        },
      }).then(res => setAccessibleThings(res));
    }
  }, [loadAccessThings, person]);

  // カードグループを編集したら名称のためだけにデータ取得しなきゃだが勿体無い
  useEffect(() => {
    (async () => {
      const nfcGroup = !nfcCard?.memberPropertyLabelId
        ? undefined
        : await FirestoreOrgNfcGroups.getById(user.organizationId, nfcCard.memberPropertyLabelId);
      setNfcGroup(nfcGroup);
    })();
  }, [nfcCard?.memberPropertyLabelId, user.organizationId]);

  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const onOpenDownload = useCallback(() => setOpenDownload(true), []);
  const onCloseDownload = useCallback(() => setOpenDownload(false), []);

  const headerActions = useMemo<WHeaderActions>(() => {
    const actions: SetRequired<WHeaderActions, 'others'> = {
      others: [
        {
          label: commonDict.delete,
          action: openDeleteDialog,
          destructive: true,
        },
      ],
    };

    if (tabId === 'log') {
      actions.others.push({
        label: dict.csvDownload,
        action: onOpenDownload,
      });
    }

    return actions;
  }, [commonDict.delete, dict.csvDownload, tabId, onOpenDownload, openDeleteDialog]);

  const headerNavigation = useMemo<PropBreadcrumb>(
    () => [
      {label: dict.securityCardManagement},
      {
        label: dict.securityCards,
        toPath: search ? `/nfc-cards?${search}` : '/nfc-cards',
      },
    ],
    [dict.securityCardManagement, dict.securityCards, search]
  );

  return (
    <Root>
      {deleteProcessing && <WLoadingComponent message={dict.processingDeleteMessage} />}
      <WHeaderNavigation title={nfcCard?.name || ''} navigation={headerNavigation} actions={headerActions} />
      {isLoading ? (
        <WLoadingComponent message={dict.loading} notTransparent />
      ) : (
        <>
          <WHeaderTab2 tabs={headerTabs} tabId={tabId} onChange={onChangeHeaderTab} />
          <WAlert
            open={deleteDialogOpen}
            title={dict.deleteMessageTitle}
            description={dict.deleteMessage}
            okButtonLabel={commonDict.delete}
            onOk={onDelete}
            running={deleteProcessing}
            onCancel={closeDeleteDialog}
            destructive={true}
          />
          {tabId === 'overview' ? (
            <WNfcCardOverview
              nfcCard={nfcCard}
              refetch={refetchNfcCard}
              nfcGroup={nfcGroup}
              person={person}
              department={department}
              office={office}
              accessibleThings={accessibleThings}
            />
          ) : (
            <WNfcCardLockUnlockLogTable cardId={nfcCardId} />
          )}
        </>
      )}
      <WNfcCardLockUnlockLogCsvDownloadDialog open={openDownload} onClose={onCloseDownload} cardId={nfcCardId} />
    </Root>
  );
}
