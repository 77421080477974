import styled from '@emotion/styled';
import {useQuery} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {generateHTML} from '@tiptap/core';
import * as Icon from '@workhub/icons';
import {IconButton, Loading} from '@workhub/ui';
import {useMemo} from 'react';

import {getAnnouncementApi} from '@/api-call/workhub-core/getAnnouncementApi';
import useDict from '@/common/hooks/useDict';
import {Locale} from '@/common/redux/state-types/localeStateType';
import WCardTemplate from '@/components/figma/card/WCardTemplate';
import WHeaderNavigation, {BreadcrumbLink} from '@/components/header/WHeaderNavigation';
import {RichTextHtmlViewer} from '@/components/rich-text-editor/RichTextHtmlViewer';
import {extensions} from '@/features/building-tenant-management/announcement/extentions';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  buildingTenantManagement: {
    default: {
      default: 'ビルテナント管理',
      [Locale.en_US]: 'Building Tenant Management',
    },
  },
  announcementManagement: {
    default: {
      default: 'お知らせ管理',
      [Locale.en_US]: 'Announcement Management',
    },
  },
  announcementContent: {
    default: {
      default: 'お知らせ内容',
      [Locale.en_US]: 'Announcement Content',
    },
  },
  back: {
    default: {
      default: '戻る',
      [Locale.en_US]: 'Back',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: var(--spacing-16);
`;

const Layout = styled.div`
  padding: var(--spacing-24);
  overflow: auto;
`;

const BodyWrapper = styled.div`
  padding: var(--spacing-24) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  height: calc(100% - 48px);
  box-sizing: border-box;
`;

export const Route = createFileRoute('/_authorized/building-tenant-management/announcement/$announcementId')({
  component: () => (
    <Authorize featureGroup='BuildingTenantManagement' feature='BuildingAnnouncementManagement'>
      <RouteComponent />
    </Authorize>
  ),
});

function RouteComponent() {
  const dict = useDict(dictDef);
  const navigate = Route.useNavigate();
  const params = Route.useParams();

  const navigation = useMemo(() => {
    const breadcrumbs: BreadcrumbLink[] = [
      {
        label: dict.buildingTenantManagement,
      },
      {
        label: dict.announcementManagement,
        toPath: '/building-tenant-management/announcement',
      },
    ];
    return breadcrumbs;
  }, [dict.announcementManagement, dict.buildingTenantManagement]);

  const {data: announcement, isLoading} = useQuery({
    queryKey: ['building-tenant-management/announcement', params.announcementId],
    queryFn: async () => {
      const {data} = await getAnnouncementApi({paths: {announcementId: params.announcementId}});
      return data;
    },
  });

  if (isLoading) {
    return <Loading centered />;
  }

  if (!announcement) {
    return null;
  }

  return (
    <Root>
      <WHeaderNavigation
        title={
          <TitleWrapper>
            <IconButton
              title={dict.back}
              onClick={() => navigate({to: '/building-tenant-management/announcement'})}
              disableTooltip
            >
              <Icon.Arrow.Back />
            </IconButton>
            <>{announcement.title}</>
          </TitleWrapper>
        }
        navigation={navigation}
      />
      <Layout>
        <WCardTemplate title={dict.announcementContent}>
          <BodyWrapper>
            <RichTextHtmlViewer html={generateHTML(announcement.content, extensions)} />
          </BodyWrapper>
        </WCardTemplate>
      </Layout>
    </Root>
  );
}
