import styled from '@emotion/styled';
import {useMutation} from '@tanstack/react-query';
import {createFileRoute, useBlocker} from '@tanstack/react-router';
import {Dialog, Field, TextButton, TextInput} from '@workhub/ui';
import {ComponentRef, useCallback, useEffect, useRef, useState} from 'react';

import {createAnnouncementApi} from '@/api-call/workhub-core/createAnnouncementApi';
import {useDialog} from '@/common/hooks/useDialog';
import useDict from '@/common/hooks/useDict';
import {useSnackbar} from '@/common/hooks/useSnackbar';
import {Locale} from '@/common/redux/state-types/localeStateType';
import WCardTemplate from '@/components/figma/card/WCardTemplate';
import WHeaderNavigation from '@/components/header/WHeaderNavigation';
import {RichTextEditor} from '@/components/rich-text-editor/RichTextEditor';
import {Authorize} from '@/routing/Authorize';

const dictDef = {
  navigation1: {
    default: {
      default: 'ビルテナント管理',
      [Locale.en_US]: 'Building Tenant Management',
    },
  },
  navigation2: {
    default: {
      default: 'お知らせ管理',
      [Locale.en_US]: 'Announcement Management',
    },
  },
  newAnnouncement: {
    default: {
      default: 'お知らせ',
      [Locale.en_US]: 'Announcement',
    },
  },
  announcementContent: {
    default: {
      default: 'お知らせ内容',
      [Locale.en_US]: 'Announcement Content',
    },
  },
  title: {
    default: {
      default: 'タイトル',
      [Locale.en_US]: 'Title',
    },
  },
  titleDescription: {
    default: {
      default: '50文字以内で入力してください',
      [Locale.en_US]: 'Please enter within 50 characters',
    },
  },
  body: {
    default: {
      default: '本文',
      [Locale.en_US]: 'Body',
    },
  },
  save: {
    default: {
      default: '保存',
      [Locale.en_US]: 'Save',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel',
    },
  },
  ok: {
    default: {
      default: 'OK',
      [Locale.en_US]: 'OK',
    },
  },
  confirmPublishing: {
    default: {
      default: 'お知らせを今すぐ公開してよろしいですか？',
      [Locale.en_US]: 'Are you sure you want to publish the announcement now?',
    },
  },
  successPublishing: {
    default: {
      default: '保存しました',
      [Locale.en_US]: 'Saved',
    },
  },
  failedPublishing: {
    default: {
      default: '保存に失敗しました',
      [Locale.en_US]: 'Failed to save',
    },
  },
  confirmCancel: {
    default: {
      default: '編集内容を破棄してよろしいですか？',
      [Locale.en_US]: 'Are you sure you want to discard the edited content?',
    },
  },
  confirmCancelDescription: {
    default: {
      default: 'この操作は取り消すことができません。本当に削除してよろしいですか？',
      [Locale.en_US]: 'This operation cannot be undone. Are you sure you want to delete it?',
    },
  },
  destroy: {
    default: {
      default: '破棄',
      [Locale.en_US]: 'Destroy',
    },
  },
  destroyAnnouncement: {
    default: {
      default: 'お知らせを破棄しました',
      [Locale.en_US]: 'Announcement destroyed',
    },
  },
  titleIsOverFifty: {
    default: {
      default: 'タイトルは50文字以内で入力してください',
      [Locale.en_US]: 'Please enter within 50 characters',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
`;

const Layout = styled.div`
  padding: var(--spacing-24);
  flex: 1;
  overflow: auto;
`;

const BodyWrapper = styled.div`
  padding: var(--spacing-24) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  height: calc(100% - 48px);
  box-sizing: border-box;
`;

const EditorLayout = styled.div`
  border-radius: 4px;
  border: 2px solid var(--border-on-light-default);
  background: var(--surface-neutral-middle);
  height: 360px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Route = createFileRoute('/_authorized/building-tenant-management/announcement/create')({
  component: () => (
    <Authorize featureGroup='BuildingTenantManagement' feature='BuildingAnnouncementManagement'>
      <RouteComponent />
    </Authorize>
  ),
});

function RouteComponent() {
  const dict = useDict(dictDef);
  const snackbar = useSnackbar();
  const navigate = Route.useNavigate();
  const {open, onOpen, onClose} = useDialog();

  const editorRef = useRef<ComponentRef<typeof RichTextEditor>>(null);
  const titleRef = useRef<HTMLInputElement>(null);

  const initialTitleRef = useRef<string>();

  const [isTitleOverFifty, setIsTitleOverFifty] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [bothChanged, setBothChanged] = useState(false);

  useEffect(() => {
    initialTitleRef.current = titleRef.current?.value;

    const intervalId = setInterval(() => {
      const currentTitle = titleRef.current?.value;
      const hasTitleChanged = currentTitle !== initialTitleRef.current;
      const hasEditorChanged = editorRef.current?.getIsEmpty() === false;

      setIsTitleOverFifty(currentTitle !== undefined && currentTitle.length > 50);
      setHasChanged(hasTitleChanged || hasEditorChanged);
      setBothChanged(hasTitleChanged && hasEditorChanged);
    }, 1000); // 1000msごとにチェック

    return () => clearInterval(intervalId);
  }, []);

  const {proceed, reset, status} = useBlocker({
    shouldBlockFn: () => !open && hasChanged,
    disabled: !hasChanged,
    withResolver: true,
  });

  const {mutate: save, isPending} = useMutation({
    mutationKey: ['createAnnouncement'],
    mutationFn: async () => {
      const title = titleRef.current?.value;
      const content = editorRef.current?.getJson();
      if (!title || !content) {
        throw new Error('content is empty');
      }
      await createAnnouncementApi({
        body: {
          title,
          content,
        },
      });
    },
    onSuccess: () => {
      snackbar.success(dict.successPublishing);
      onClose();
      navigate({to: '/building-tenant-management/announcement'});
    },
    onError: () => {
      snackbar.fail(dict.failedPublishing);
    },
  });

  const onDestroy = useCallback(() => {
    proceed?.();
    snackbar.success(dict.destroyAnnouncement);
  }, [dict.destroyAnnouncement, proceed, snackbar]);

  return (
    <Root>
      <WHeaderNavigation
        title={dict.newAnnouncement}
        navigation={[{label: dict.navigation1}, {label: dict.navigation2}]}
        actions={{
          secondary: {
            label: dict.save,
            action: onOpen,
            buttonColor: 'primary',
            disabled: !bothChanged || isTitleOverFifty,
          },
          primary: {
            label: dict.cancel,
            action: () => navigate({to: '/building-tenant-management/announcement'}),
            buttonColor: 'secondary',
          },
        }}
      />
      <Layout>
        <WCardTemplate title={dict.announcementContent} minHeight={'100%'}>
          <BodyWrapper>
            <Field
              label={dict.title}
              required
              description={dict.titleDescription}
              errorMessage={isTitleOverFifty ? dict.titleIsOverFifty : undefined}
            >
              <TextInput ref={titleRef} />
            </Field>
            <Field label={dict.body} required className='content'>
              <EditorLayout>
                <RichTextEditor ref={editorRef} content='' />
              </EditorLayout>
            </Field>
          </BodyWrapper>
        </WCardTemplate>
      </Layout>
      <Dialog open={open}>
        <Dialog.Title>{dict.confirmPublishing}</Dialog.Title>
        <Dialog.Actions>
          <TextButton color='secondary' onClick={onClose} disabled={isPending}>
            {dict.cancel}
          </TextButton>
          <TextButton color='primary' onClick={save} processing={isPending} disabled={isPending}>
            {dict.ok}
          </TextButton>
        </Dialog.Actions>
      </Dialog>
      <Dialog open={status === 'blocked'}>
        <Dialog.Title>{dict.confirmCancel}</Dialog.Title>
        <Dialog.Content>{dict.confirmCancelDescription}</Dialog.Content>
        <Dialog.Actions>
          <TextButton color='secondary' onClick={reset} disabled={isPending}>
            {dict.cancel}
          </TextButton>
          <TextButton color='disruptive' onClick={onDestroy} processing={isPending} disabled={isPending}>
            {dict.destroy}
          </TextButton>
        </Dialog.Actions>
      </Dialog>
    </Root>
  );
}
