import {Navigate, useRouterState} from '@tanstack/react-router';
import * as Icon from '@workhub/icons';
import {LazyExoticComponent, lazy, ComponentType} from 'react';
import type {RouteObject} from 'react-router';

import {Feature, FeatureGroup} from '@/common/feature-control/featureDefinitions';
import {Authorize} from '@/routing/Authorize';
import {MainMenuDictKey} from '@/wscreens/routing/def/MainMenuDictDef';
import {MainMenuGroupKey} from '@/wscreens/routing/def/MainMenuGroupDictDef';

// サイドメニューのUI制御用のタイプ
type RouteMenuType = {
  dictKey: MainMenuDictKey | ''; // メニューに出さないものにkey指定するのつらいので空文字はOKにする。。そもそも詳細画面とかはここに入れないようにしたい。権限も画面側で設定。
  groupKey?: MainMenuGroupKey;
  icon?: ComponentType<any>;
  divider?: 'top' | 'bottom' | 'both';
  hide?: (featureGroup: FeatureGroup[]) => boolean;
};

// 権限管理・ルーティング用のタイプ
type RouteOrLinkResourceType = {
  resource: {type: 'route'; path: string; component?: LazyExoticComponent<any>} | {type: 'link'; url: string};
  feature: Feature;
  featureGroup: FeatureGroup;
};

type RouteWithoutChildren = {
  type: 'withoutChildren';
} & RouteMenuType &
  RouteOrLinkResourceType;

type RouteWithChildren = {
  type: 'withChildren';
  children: Array<
    {
      fontSize?: number;
    } & RouteOrLinkResourceType &
      Omit<RouteMenuType, 'divider'>
  >;
} & RouteMenuType;

export type Route = RouteWithChildren | RouteWithoutChildren;

// アカウント
const MypageScreen = lazy(() => import('@/wscreens/mypage/WMypageScreen'));
const AnnouncementScreen = lazy(() => import('@/wscreens/announcement/AnnouncementScreen'));
const AnnouncementDetail = lazy(() => import('@/wscreens/announcement/AnnouncementDetail'));
const TrafficConditions = lazy(() => import('@/wscreens/spaces/traffic-conditions/TrafficConditionsScreen'));
const LocationSearchScreen = lazy(() => import('@/wscreens/location-search/LocationSearchScreen'));
const MySchedule = lazy(() => import('@/wscreens/reservation-my-schedule/ReservationMySchedule'));
const ReservationSpaceCalendar = lazy(() => import('@/wscreens/reservation-space-calendar/ReservationSpaceCalendar'));
const ReservationThingCalendar = lazy(() => import('@/wscreens/reservation-thing-calendar/ReservationThingCalendar'));
const ReservationLockerCalendar = lazy(
  () => import('@/wscreens/reservation-locker-calendar/ReservationLockerCalendarScreen')
);
// サードプレイス
const WCoworkingBillingScreen = lazy(() => import('@/wscreens/coworking-billings/WBillingsScreen'));
const WCoworkingBillingDetail = lazy(() => import('@/wscreens/coworking-billings/WBillingsDetail'));
const WCoworkingReceivedMoneyManagementScreen = lazy(
  () => import('@/wscreens/coworking-received-money/TPRoutingReceivedMoneyScreen')
);
const WCoworkingReceivedMoneyDetail = lazy(
  () => import('@/wscreens/coworking-received-money/TPRoutingReceivedMoneyDetail')
);
const WCoworkingRefundManagementScreen = lazy(() => import('@/wscreens/coworking-refund/WRefundManagementScreen'));

const DropInPricePlansScreen = lazy(
  () => import('@/wscreens/third-place/drop-in/DropInPricePlans/DropInPricePlansScreen/DropInPricePlansScreen')
);
const DropInPricePlansDetailScreen = lazy(
  () =>
    import('@/wscreens/third-place/drop-in/DropInPricePlans/DropInPricePlansDetailScreen/DropInPricePlansDetailScreen')
);
const DropInPlanDetailScreen = lazy(
  () => import('@/wscreens/third-place/drop-in/DropInPlans/DropInPlanDetailScreen/DropInPlanDetailScreen')
);
const DropInPlansScreen = lazy(
  () => import('@/wscreens/third-place/drop-in/DropInPlans/DropInPlansScreen/DropInPlansScreen')
);
const WCoworkingContractPlanScreen = lazy(
  () => import('@/wscreens/coworking-contract-plans/WContractPlanScreen/WContractPlanScreen')
);
const WCoworkingContractPlanDetail = lazy(
  () => import('@/wscreens/coworking-contract-plans/WDetailContractPlan/WDetailContractPlan')
);
const WCoworkingOptionScreen = lazy(() => import('@/wscreens/third-place/options/WOptionScreen'));
const WCoworkingOptionDetail = lazy(() => import('@/wscreens/third-place/options/WOptionDetail'));
const WReservationThirdPlaceCustomerMeetingRoomScreen = lazy(
  () => import('@/wscreens/reservations/WReservationThirdPlaceCustomerMeetingRoomScreen')
);
const WReservationThirdPlaceCustomerMeetingRoomDetailScreen = lazy(
  () => import('@/wscreens/reservations/WReservationThirdPlaceCustomerMeetingRoomDetailScreen')
);
const WCoworkingGuestScreen = lazy(() => import('@/wscreens/coworking-guests/TPMemberScreen'));
const WCoworkingGuestDetail = lazy(() => import('@/wscreens/coworking-guests/v2Detail/ThirdDetailCustomerScreen'));
const WCoworkingGuestBillDetail = lazy(() => import('@/wscreens/coworking-guests/WCoworkingGuestBillDetail'));
const TPCustomerScreen = lazy(() => import('@/wscreens/third-place/customer-screen/pages/EsCustomerScreen'));
const WCoworkingTenantDetail = lazy(() => import('@/wscreens/coworking-tenant/WTenantDetail'));
const WContractPlanRelease = lazy(() => import('@/wscreens/third-place/release-contract-plan/ReleaseContractPlan'));
const WContractCreateScreen = lazy(() => import('@/wscreens/third-place/contract-add/ContractBase'));
const WCoworkingContractScreen = lazy(() => import('@/wscreens/contracts/EsContractScreen'));
const WCoworkingContractDetail = lazy(() => import('@/wscreens/contracts/v2Detail/ThirdDetailContractScreen'));
const WContractRenewScreen = lazy(() => import('@/wscreens/third-place/contract-renew/ContractRenew'));
const WCoworkingUpdateRulesScreen = lazy(() => import('@/wscreens/coworking-update-rules/WCoworkingUpdateRulesScreen'));
const WCoworkingUpdateRulesDetail = lazy(() => import('@/wscreens/coworking-update-rules/WCoworkingUpdateRulesDetail'));
const WCoworkingCancelRulesScreen = lazy(() => import('@/wscreens/coworking-cancel-rule/WCoworkingCancelRuleScreen'));
const WCoworkingCancelRulesDetail = lazy(() => import('@/wscreens/coworking-cancel-rule/WCoworkingCancelRuleDetail'));
const WCoworkingClaimCycleScreen = lazy(() => import('@/wscreens/coworking-claim-cycle/WCoworkingClaimCycleScreen'));
const WInvoiceSettingScreen = lazy(() => import('@/wscreens/third-place/invoice-setting/InvoiceSettingScreen'));
const WCoworkingPaymentMethodScreen = lazy(() => import('@/wscreens/coworking-payment-method/WPaymentMethodScreen'));
const WorkpassSettingScreen = lazy(() => import('@/wscreens/third-place/workpass-settings/WorkpassSettingScreen'));
// ビルテナント
const MultiTenantContractPlansScreen = lazy(
  () =>
    import('@/wscreens/multi-tenant-owner/contract-plans/MultiTenantContractPlansScreen/MultiTenantContractPlansScreen')
);
const MultiTenantContractPlansDetailScreen = lazy(
  () =>
    import(
      '@/wscreens/multi-tenant-owner/contract-plans/MultiTenantContractPlansDetailScreen/MultiTenantContractPlansDetailScreen'
    )
);
const MultiTenantContractsScreen = lazy(
  () => import('@/wscreens/multi-tenant-owner/contracts/MultiTenantContractsScreen/MultiTenantContractsScreen')
);
const MultiTenantContractsDetailScreen = lazy(
  () =>
    import('@/wscreens/multi-tenant-owner/contracts/MultiTenantContractsDetailScreen/MultiTenantContractsDetailScreen')
);
const MultiTenantContractsCreateScreen = lazy(
  () =>
    import('@/wscreens/multi-tenant-owner/contracts/MultiTenantContractsCreateScreen/MultiTenantContractsCreateScreen')
);
// 申請
const WApprovalTrayScreen = lazy(() => import('@/wscreens/application/WApprovalTrayScreen'));
const WApplicationDetailScreen = lazy(() => import('@/wscreens/application/detail/WApplicationDetailScreen'));
// モニタリング
const WSendMailJobScreen = lazy(() => import('@/wscreens/send-mail-job/WSendMailJobScreen'));
const WSendMailJobDetailScreen = lazy(() => import('@/wscreens/send-mail-job/WSendMailJobDetailScreen'));
const WKeyAndTicketsScreen = lazy(() => import('@/wscreens/system-reports/key-tickets/WKeyAndTicketsScreen'));
const WFaceRecognitionStatusScreen = lazy(
  () => import('@/wscreens/system-reports/face-recognition/WFaceRecognitionStatusScreen')
);
const WLockAndUnlockScreen = lazy(() => import('@/wscreens/spaces/lock-unlock/WLockAndUnlockScreen'));
const OutlookSyncHistories = lazy(() => import('@/wscreens/outlook-sync-histories/OutlookSyncHistoriesScreen'));
const OutlookSyncHistoriesDetail = lazy(() => import('@/wscreens/outlook-sync-histories/OutlookSyncHistoriesDetail'));

const GoogleCalendarSyncHistories = lazy(
  () => import('@/wscreens/google-calendar-sync-histories/GoogleCalendarSyncHistoriesScreen')
);
const GoogleCalendarSyncHistoriesDetail = lazy(
  () => import('@/wscreens/google-calendar-sync-histories/GoogleCalendarSyncHistoriesDetail')
);

const WReservationListScreen = lazy(() => import('@/wscreens/reservation-history/WReservationHistoryScreen'));
const WReservationHistoryDetail = lazy(() => import('@/wscreens/reservation-history/WReservationHistoryDetail'));
// const SpaceUsageReportScreen = lazy(
//   () => import('@/wscreens/workspace-view/space-usage-report/SpaceUsageReportScreen')
// );
const FMDashboard = lazy(() => import('@/wscreens/workspace-view/space-usage-report/SpaceUsageReportScreen'));
const FMDashboardSatisfactionList = lazy(
  () => import('@/wscreens/workspace-view/space-usage-report/SpaceUsageReportSatisfactionListScreen')
);
const SpaceUsageReportSatisfactionDetailBySpaceIdScreen = lazy(
  () => import('@/wscreens/workspace-view/space-usage-report/SpaceUsageReportSatisfactionDetailBySpaceIdScreen')
);

const EmployeesAttendanceScreen = lazy(() => import('@/wscreens/employees/attendance/EmployeesAttendanceScreen'));
const WSecurityDeviceAlertInquiryScreen = lazy(
  () => import('@/wscreens/security-devices-alert-inquiry/WSecurityDeviceAlertInquiryScreen')
);
const AlertAntiPassBackScreen = lazy(() => import('@/wscreens/workspace-view/anti-pass-back/AlertAntiPassBackScreen'));
// ユーザー
const WPeopleScreen = lazy(() => import('@/wscreens/people/WPeopleScreen'));
const WPeopleDetail = lazy(() => import('@/wscreens/people/WPeopleDetail'));
const WDepartmentScreen = lazy(() => import('@/wscreens/departments/WDepartmentScreen'));
const WUserGroupsDetail = lazy(() => import('@/wscreens/user-groups/WUserGroupsDetail'));
const WPositionScreen = lazy(() => import('@/wscreens/positions/WPositionScreen'));
const WEmploymentStatusScreen = lazy(() => import('@/wscreens/employment-status/WEmploymentStatusScreen'));
const WUserGroupsScreen = lazy(() => import('@/wscreens/user-groups/WUserGroupsScreen'));
const WGuestScreen = lazy(() => import('@/wscreens/guest/WGuestScreen'));
const WGuestDetail = lazy(() => import('@/wscreens/guest/WGuestDetail'));
const WVendorScreen = lazy(() => import('@/wscreens/vendor/WVendorScreen'));
const WVendorDetail = lazy(() => import('@/wscreens/vendor/WVendorDetail'));
const WVendorGroupScreen = lazy(() => import('@/wscreens/vendor-groups/WVendorGroupScreen'));
const WGuestGroupScreen = lazy(() => import('@/wscreens/guest-groups/WGuestGroupScreen'));
const WCustomerScreen = lazy(() => import('@/wscreens/customer/WCustomerScreen'));
const WCustomerDetail = lazy(() => import('@/wscreens/customer/WCustomerDetail'));
const WFaceRegistrationScreen = lazy(() => import('@/wscreens/face-registration/WFaceRegistrationScreen'));

// スペース
const WRegionScreen = lazy(() => import('@/wscreens/spaces/regions/WRegionScreen'));
const WBuildingScreen = lazy(() => import('@/wscreens/spaces/buildings/WBuildingScreen'));
const WFloorScreen = lazy(() => import('@/wscreens/spaces/floors/WFloorScreen'));
const WAreaScreen = lazy(() => import('@/wscreens/spaces/areas/WAreaScreen'));
const WAreaDetail = lazy(() => import('@/wscreens/spaces/areas/WAreaDetail'));
const WWorkBoothScreen = lazy(() => import('@/wscreens/work-booth/WWorkBoothScreen'));
const WWorkspotsScreen = lazy(() => import('@/wscreens/spaces/workspots/WWorkspotsScreen'));
const WWorkspotDetailScreen = lazy(() => import('@/wscreens/spaces/workspots/WWorkspotDetailScreen'));
const AntiPassBackScreen = lazy(() => import('@/wscreens/spaces/anti-pass-back/AntiPassBackScreen'));
const WSecurityDeviceScreen = lazy(() => import('@/wscreens/security-devices/WSecurityDeviceScreen'));
const WSecurityDeviceDetail = lazy(() => import('@/wscreens/security-devices/detail/WSecurityDeviceDetail'));
const WEquipmentScreen = lazy(() => import('@/wscreens/equipments/WEquipmentScreen'));
const WEquipmentDetail = lazy(() => import('@/wscreens/equipments/WEquipmentDetail'));
const WLockerScreen = lazy(() => import('@/wscreens/lockers/WLockerScreen'));
const WLockerDetailScreen = lazy(() => import('@/wscreens/lockers/WLockerDetailScreen'));
const WShelfScreen = lazy(() => import('@/wscreens/shelves/WShelfScreen'));
const WReceptionScreen = lazy(() => import('@/wscreens/receptions/WReceptionScreen/WReceptions'));
const WReceptionDetail = lazy(() => import('@/wscreens/receptions/detail/WReceptionDetail'));
const WReceptionGuests = lazy(() => import('@/wscreens/receptions/WReceptionGuests'));
// アクセスセキュリティ
const AuthorityScreen = lazy(() => import('@/wscreens/authority/AuthorityScreen'));
const WAttendanceSettingScreen = lazy(() => import('@/wscreens/attendance-setting/WAttendanceSettingScreen'));
const WMailTemplateScreen = lazy(() => import('@/wscreens/mail-templates/WMailTemplateScreen'));
const WLinkageSettingScreen = lazy(() => import('@/wscreens/linkage-setting/WLinkageSettingScreen'));
const WSSOSettingScreen = lazy(() => import('@/wscreens/sso-settings/WSSOSettingScreen'));
const WOrganizationSettingScreen = lazy(() => import('@/wscreens/organization-setting/WOrganizationSettingScreen'));
const WNotificationSettingScreen = lazy(() => import('@/wscreens/notification-settings/WNotificationSettingScreen'));
const WReceptionSettingTemplateScreen = lazy(() => import('@/wscreens/reception-setting/WReceptionSettingTemplates'));
const WInvitationMailSettingScreen = lazy(
  () => import('@/wscreens/invitation-mail-setting/WInvitationMailSettingScreen')
);
const WReceptionSettingScreen = lazy(() => import('@/wscreens/reception-setting/WReceptionSettings'));
const WReservationUnavailableTimeSettingDetail = lazy(
  () => import('@/wscreens/reservation-unavailable-time-settings/detail/WReservationUnavailableTimeSettingDetail')
);
const ReservationSetting = lazy(() => import('@/wscreens/reservation-settings/ReservationSettingsRoot'));
const WReservationUnavailableTimeSettingScreen = lazy(
  () => import('@/wscreens/reservation-unavailable-time-settings/WReservationUnavailableTimeSettingScreen')
);
const SignageEmployeesAttendanceScreen = lazy(
  () => import('@/wscreens/employees/attendance/SignageEmployeesAttendanceScreen')
);
const SignageLocationSearchScreen = lazy(() => import('@/wscreens/location-search/SignageLocationSearchScreen'));
const SignageTrafficConditions = lazy(
  () => import('@/wscreens/spaces/signage-traffic-conditions/SignageTrafficConditionsScreen')
);
const WNfcCardTemporaryGroupScreen = lazy(
  () => import('@/wscreens/nfc-card-temporary-use-group/WNfcTemporaryGroupScreen')
);
const WNfcCardTemporaryGroupDetail = lazy(
  () => import('@/wscreens/nfc-card-temporary-use-group/WNfcCardTemporaryGroupDetail')
);
const WNfcCardTemporaryAttachAccessRightDetail = lazy(
  () => import('@/wscreens/nfc-card-temporary-use-group/access-right/detail/WNfcCardTemporaryAttachAccessRightDetail')
);
const WNfcCardGroupScreen = lazy(() => import('@/wscreens/nfc-card-group/WNfcCardGroupScreen'));
const WNfcCardGroupDetail = lazy(() => import('@/wscreens/nfc-card-group/WNfcCardGroupDetail'));
const WManualScreen = lazy(() => import('@/wscreens/manuals/WManualScreen'));
const KeyBundle = lazy(() => import('@/wscreens/key-bundle/KeyBundleScreen'));
const KeyBundleDetail = lazy(() => import('@/wscreens/key-bundle/KeyBundleDetailScreen'));
const AccessRights = lazy(() => import('@/wscreens/access-rights/AccessRightsScreen'));
const AccessRightsDetail = lazy(() => import('@/wscreens/access-rights/AccessRightsDetailScreen'));
const WFixedPasscodeScreen = lazy(() => import('@/wscreens/passcode/fixed-passcode/WFixedPasscodeScreen'));
// その他
const WDataImportScreen = lazy(() => import('@/wscreens/data-import/wDataImportScreen'));
const WDataImportDetail = lazy(() => import('@/wscreens/data-import/detail/wDataImportDetail'));
const ContractImportScreen = lazy(() => import('@/wscreens/data-import/job-type/contract/contractImportScreen'));
const ReservationImportScreen = lazy(
  () => import('@/wscreens/data-import/job-type/reservation/reservationImportScreen')
);
const ErrorDisplayScreen = lazy(() => import('@/wscreens/no-authority/ErrorDisplayScreen'));
const WAnnouncementManagementScreen = lazy(
  () => import('@/wscreens/for-bitkey/announcement-management/WAnnouncementScreen')
);
const WManualManagementScreen = lazy(() => import('@/wscreens/for-bitkey/WManualManagementScreen'));
const EventParticipantReservationScreen = lazy(
  () => import('@/wscreens/events/participants/reservations/EventParticipantReservationScreen')
);
const EventReservationListScreen = lazy(
  () => import('@/wscreens/events/participants/reservations/EventReservationListScreen')
);
const FMDashboardSetting = lazy(() => import('@/wscreens/fm-dashboard-setting/FMDashboardSetting'));
const FMDashboardSatisfactionSetting = lazy(
  () => import('@/wscreens/fm-dashboard-setting/satisfaction/FMDashboardSatisfactionSetting')
);

export const routeObjects: RouteObject[] = [
  {
    path: '/announcements',
    element: <Authorize featureGroup='Announcement' feature='announcement' />,
    children: [
      {index: true, element: <AnnouncementScreen />},
      {path: 'detail', element: <AnnouncementDetail />},
    ],
  },
  {
    element: <Authorize featureGroup='ReservationCalendar' />,
    children: [
      {
        path: '/reservation-my-schedule',
        element: (
          <Authorize feature='reservationCalendarMySchedule'>
            <MySchedule />
          </Authorize>
        ),
      },
      {
        path: '/reservation-calendar',
        element: (
          <Authorize feature='reservationCalendar'>
            <ReservationSpaceCalendar />
          </Authorize>
        ),
      },
      {
        path: '/reservation-thing-calendar',
        element: (
          <Authorize feature='reservationThingCalendar'>
            <ReservationThingCalendar />
          </Authorize>
        ),
      },
      {
        path: '/lockers/reservation-calendar',
        element: (
          <Authorize feature='v2LockerReservationCalendar'>
            <ReservationLockerCalendar />
          </Authorize>
        ),
      },
    ],
  },
  {
    path: '/space/traffic-conditions',
    element: (
      <Authorize featureGroup='TrafficConditions' feature='trafficConditions'>
        <TrafficConditions />
      </Authorize>
    ),
  },
  {
    path: '/workspace-view/locationSearch',
    element: (
      <Authorize featureGroup='V2LocationSearch' feature='v2LocationSearch'>
        <LocationSearchScreen />
      </Authorize>
    ),
  },
  {
    path: '/third-place',
    element: <Authorize featureGroup='ThirdPlace' />,
    children: [
      {
        element: <Authorize feature='ThirdPlace' />,
        children: [{path: 'contract-plan/release', element: <WContractPlanRelease />}],
      },
      {
        path: 'contracts',
        element: <Authorize feature='ThirdPlaceContract' />,
        children: [
          {index: true, element: <WCoworkingContractScreen />},
          {path: 'create', element: <WContractCreateScreen />},
          {path: 'detail', element: <WCoworkingContractDetail />},
          {path: 'renew', element: <WContractRenewScreen />},
        ],
      },
      {
        path: 'billing',
        element: <Authorize feature='ThirdPlaceBilling' />,
        children: [
          {path: 'screen', element: <WCoworkingBillingScreen />},
          {path: 'detail', element: <WCoworkingBillingDetail />},
        ],
      },
      {
        path: 'received-money',
        element: <Authorize feature='ThirdPlaceReceivedMoney' />,
        children: [
          {path: 'screen', element: <WCoworkingReceivedMoneyManagementScreen />},
          {path: 'detail', element: <WCoworkingReceivedMoneyDetail />},
        ],
      },
      {
        path: 'refund',
        element: <Authorize feature='ThirdPlaceRefund' />,
        children: [{path: 'screen', element: <WCoworkingRefundManagementScreen />}],
      },
      {
        path: 'reservation',
        element: <Authorize feature='reservationLog' />,
        children: [
          {index: true, element: <WReservationThirdPlaceCustomerMeetingRoomScreen />},
          {path: 'detail', element: <WReservationThirdPlaceCustomerMeetingRoomDetailScreen />},
        ],
      },
      {
        path: 'contract-plans',
        element: <Authorize feature='ThirdPlaceContractPlans' />,
        children: [
          {index: true, element: <WCoworkingContractPlanScreen />},
          {path: 'detail', element: <WCoworkingContractPlanDetail />},
        ],
      },
      {
        path: 'options',
        element: <Authorize feature='ThirdPlaceOptions' />,
        children: [
          {index: true, element: <WCoworkingOptionScreen />},
          {path: 'detail', element: <WCoworkingOptionDetail />},
        ],
      },
      {
        path: 'drop-in/plans',
        element: <Authorize feature='DropInPlans' />,
        children: [
          {index: true, element: <DropInPlansScreen />},
          {path: 'detail', element: <DropInPlanDetailScreen />},
        ],
      },
      {
        path: 'drop-in/price-plans',
        element: <Authorize feature='DropInPricePlans' />,
        children: [
          {index: true, element: <DropInPricePlansScreen />},
          {path: 'detail', element: <DropInPricePlansDetailScreen />},
        ],
      },
      {
        path: 'update-rules',
        element: <Authorize feature='ThirdPlaceUpdateRules' />,
        children: [
          {index: true, element: <WCoworkingUpdateRulesScreen />},
          {path: 'detail', element: <WCoworkingUpdateRulesDetail />},
        ],
      },
      {
        path: 'cancel-rules',
        element: <Authorize feature='ThirdPlaceCancelRules' />,
        children: [
          {index: true, element: <WCoworkingCancelRulesScreen />},
          {path: 'detail', element: <WCoworkingCancelRulesDetail />},
        ],
      },
      {
        path: 'claim-cycles',
        element: (
          <Authorize feature='ThirdPlaceClaimCycles'>
            <WCoworkingClaimCycleScreen />
          </Authorize>
        ),
      },
      {
        path: 'invoice-setting',
        element: (
          <Authorize feature='ThirdPlaceInvoice'>
            <WInvoiceSettingScreen />
          </Authorize>
        ),
      },
      {
        path: 'payment-method',
        element: (
          <Authorize feature='ThirdPlacePaymentMethod'>
            <WCoworkingPaymentMethodScreen />
          </Authorize>
        ),
      },
      {
        path: 'workhubPassSetting',
        element: (
          <Authorize feature='ThirdPlacePassSite'>
            <WorkpassSettingScreen />
          </Authorize>
        ),
      },
    ],
  },
  {
    // FIXME: /third-place/ 以下へ移動したい
    path: '/settings/invitationMailSetting',
    element: (
      <Authorize featureGroup='ThirdPlace' feature='InviteMailSettingManager'>
        <WInvitationMailSettingScreen />
      </Authorize>
    ),
  },
  {
    path: '/multi-tenant-owner',
    element: <Authorize featureGroup='V2MultiTenantOwner' />,
    children: [
      {
        path: 'contracts',
        element: <Authorize feature='V2MultiTenantOwnerContract' />,
        children: [
          {index: true, element: <MultiTenantContractsScreen />},
          {path: 'create', element: <MultiTenantContractsCreateScreen />},
          {path: 'detail', element: <MultiTenantContractsDetailScreen />},
        ],
      },
      {
        path: 'contract-plans',
        element: <Authorize feature='V2MultiTenantOwnerContractPlan' />,
        children: [
          {index: true, element: <MultiTenantContractPlansScreen />},
          {path: 'detail', element: <MultiTenantContractPlansDetailScreen />},
        ],
      },
    ],
  },
  {
    // パス的には third-place だけどマルチテナントでも使われている……
    path: '/third-place',
    element: <Authorize featureGroup={{OR: ['ThirdPlace', 'V2MultiTenantOwner']}} />,
    children: [
      {
        path: 'guest',
        element: <Authorize feature='ThirdPlaceGuest' />,
        children: [
          {index: true, element: <WCoworkingGuestScreen />},
          {path: 'detail', element: <WCoworkingGuestDetail />},
          {path: 'bill/detail', element: <WCoworkingGuestBillDetail />},
        ],
      },
      {
        path: 'tenant',
        element: <Authorize feature='ThirdPlaceCustomer' />,
        children: [
          {index: true, element: <TPCustomerScreen />},
          {path: 'detail', element: <WCoworkingTenantDetail />},
        ],
      },
    ],
  },
  {
    path: '/application/approval-tray',
    element: <Authorize featureGroup='Application' feature='Application' />,
    children: [
      {index: true, element: <WApprovalTrayScreen />},
      {path: 'detail', element: <WApplicationDetailScreen />},
    ],
  },
  {
    path: '/workspace-view/fm-dashboard',
    element: <Authorize featureGroup='FMDashboard' feature='FMDashboard' />,
    children: [
      {index: true, element: <FMDashboard />},
      {path: 'satisfaction', element: <FMDashboardSatisfactionList />},
      {path: 'satisfaction/:spaceId', element: <SpaceUsageReportSatisfactionDetailBySpaceIdScreen />},
    ],
  },
  {
    path: '/workspace-view/attendance',
    element: (
      <Authorize featureGroup='V2EmployeesAttendanceReport' feature='v2EmployeesAttendance'>
        <EmployeesAttendanceScreen />
      </Authorize>
    ),
  },
  {
    path: '/workspace-view/alert-anti-pass-back',
    element: (
      <Authorize featureGroup='AntiPassBack' feature='antiPassBackAlert'>
        <AlertAntiPassBackScreen />
      </Authorize>
    ),
  },
  {
    element: <Authorize featureGroup='Report' />,
    children: [
      {
        path: '/send-mail-job',
        element: <Authorize feature='sendMailJob' />,
        children: [
          {index: true, element: <WSendMailJobScreen />},
          {path: 'detail', element: <WSendMailJobDetailScreen />},
        ],
      },
      {
        path: '/system-reports/key-tickets',
        element: (
          <Authorize feature={{AND: ['AppKeys', 'accessControlV1']}} /* ACv2 では利用不可 */>
            <WKeyAndTicketsScreen />
          </Authorize>
        ),
      },
      {
        path: '/system-reports/face-recognition-status',
        element: (
          <Authorize feature={{AND: ['faceRecognitionStatus', 'accessControlV1']}} /* ACv2 では利用不可 */>
            <WFaceRecognitionStatusScreen />
          </Authorize>
        ),
      },
      {
        path: '/lock-unlock',
        element: (
          <Authorize feature='unlockLog'>
            <WLockAndUnlockScreen />
          </Authorize>
        ),
      },
    ],
  },
  {
    element: <Authorize featureGroup='V2MemberManagement' />,
    children: [
      {
        path: '/mypage',
        element: (
          <Authorize feature='mypage'>
            <MypageScreen />
          </Authorize>
        ),
      },
      {
        path: '/people',
        element: <Authorize feature='v2Members' />,
        children: [
          {index: true, element: <WPeopleScreen />},
          {path: 'detail', element: <WPeopleDetail />},
        ],
      },
      {
        path: '/departments',
        element: <Authorize feature='v2OfficeDepartments' />,
        children: [
          {index: true, element: <WDepartmentScreen />},
          {path: 'detail', element: <WUserGroupsDetail />},
        ],
      },
      {
        path: '/positions',
        element: <Authorize feature='v2Positions' />,
        children: [
          {index: true, element: <WPositionScreen />},
          {path: 'detail', element: <WUserGroupsDetail />},
        ],
      },
      {
        path: '/employment-status',
        element: <Authorize feature='v2EmploymentStatuses' />,
        children: [
          {index: true, element: <WEmploymentStatusScreen />},
          {path: 'detail', element: <WUserGroupsDetail />},
        ],
      },
      {
        path: '/user-groups',
        element: <Authorize feature='v2EmployeeGroupSettings' />,
        children: [
          {index: true, element: <WUserGroupsScreen />},
          {path: 'detail', element: <WUserGroupsDetail />},
        ],
      },
    ],
  },
  {
    path: '/visitors',
    element: <Authorize featureGroup='V2VisitorManagement' feature='v2Visitors' />,
    children: [
      {index: true, element: <WGuestScreen />},
      {path: 'detail', element: <WGuestDetail />},
    ],
  },
  {
    path: '/visitor-groups',
    element: (
      <Authorize featureGroup='V2VisitorGroupManagement' feature='v2Visitors'>
        <WGuestGroupScreen />
      </Authorize>
    ),
  },
  {
    path: '/customers',
    element: <Authorize featureGroup='V2CustomerManagement' feature='v2Customers' />,
    children: [
      {index: true, element: <WCustomerScreen />},
      {path: 'detail', element: <WCustomerDetail />},
    ],
  },
  {
    path: '/vendors',
    element: <Authorize featureGroup='V2CastManagement' feature='v2Casts' /* TODO: 書き換え */ />,
    children: [
      {index: true, element: <WVendorScreen />},
      {path: 'detail', element: <WVendorDetail />},
    ],
  },
  {
    path: '/vendor-groups',
    element: (
      <Authorize featureGroup='V2CastManagement' feature='v2CastGroups' /* TODO: 書き換え */>
        <WVendorGroupScreen />
      </Authorize>
    ),
  },
  {
    path: '/face-registration',
    element: (
      <Authorize featureGroup='FaceRegistration' feature='FaceRegistration'>
        <WFaceRegistrationScreen />
      </Authorize>
    ),
  },
  {
    element: <Authorize featureGroup='V2Spaces' />,
    children: [
      {
        path: '/regions',
        element: (
          <Authorize feature='v2Regions'>
            <WRegionScreen />
          </Authorize>
        ),
      },
      {
        path: '/buildings',
        element: (
          <Authorize feature='v2Buildings'>
            <WBuildingScreen />
          </Authorize>
        ),
      },
      {
        path: '/floors',
        element: (
          <Authorize feature='v2Floors'>
            <WFloorScreen />
          </Authorize>
        ),
      },
      {
        path: '/areas',
        element: <Authorize feature='v2Areas' />,
        children: [
          {index: true, element: <WAreaScreen />},
          {path: 'detail', element: <WAreaDetail />},
        ],
      },
      {
        path: '/reservation-history',
        element: <Authorize feature='reservationLog' />,
        children: [
          {index: true, element: <WReservationListScreen />},
          {path: 'detail', element: <WReservationHistoryDetail />},
        ],
      },
      {
        path: '/outlook-sync-histories',
        element: <Authorize feature='outlookReservationSyncHistories' />,
        children: [
          {index: true, element: <OutlookSyncHistories />},
          {path: ':id', element: <OutlookSyncHistoriesDetail />},
        ],
      },
      {
        path: '/google-calendar-sync-histories',
        element: <Authorize feature='GoogleCalendarSyncHistories' />,
        children: [
          {index: true, element: <GoogleCalendarSyncHistories />},
          {path: ':id', element: <GoogleCalendarSyncHistoriesDetail />},
        ],
      },
    ],
  },
  {
    path: '/work-booths',
    element: <Authorize featureGroup='WorkBooth' feature='WorkBooth' />,
    children: [
      {index: true, element: <WWorkBoothScreen />},
      {path: 'detail', element: <WAreaDetail />},
    ],
  },
  {
    path: '/workspots',
    element: <Authorize featureGroup='V2Workspot' feature='v2Workspots' />,
    children: [
      {index: true, element: <WWorkspotsScreen />},
      {path: 'detail', element: <WWorkspotDetailScreen />},
    ],
  },
  {
    path: '/anti-pass-back',
    element: <Authorize featureGroup='AntiPassBack' feature='antiPassBackArea' />,
    children: [
      {index: true, element: <AntiPassBackScreen />},
      {path: ':settingId', element: <AntiPassBackScreen />},
    ],
  },
  {
    path: '/security-devices',
    element: <Authorize featureGroup='V2SecurityDevices' />,
    children: [
      {
        element: <Authorize feature='v2SecurityDevices' />,
        children: [
          {index: true, element: <WSecurityDeviceScreen />},
          {path: 'detail', element: <WSecurityDeviceDetail />},
        ],
      },
      {
        path: 'alert-inquiry',
        element: (
          <Authorize feature='v2SecurityDeviceAlert'>
            <WSecurityDeviceAlertInquiryScreen />
          </Authorize>
        ),
      },
    ],
  },
  {
    path: '/equipments',
    element: <Authorize featureGroup='V2Equipment' feature='v2Equipments' />,
    children: [
      {index: true, element: <WEquipmentScreen />},
      {path: 'detail', element: <WEquipmentDetail />},
    ],
  },
  {
    path: '/lockers',
    element: <Authorize featureGroup='V2Locker' feature='V2Lockers' />,
    children: [
      {index: true, element: <WLockerScreen />},
      {path: 'detail', element: <WLockerDetailScreen />},
    ],
  },
  {
    path: '/shelves',
    element: <Authorize featureGroup='Shelf' feature='shelves' />,
    children: [
      {index: true, element: <WShelfScreen />},
      {path: ':shelfId', element: <WShelfScreen />},
      {path: ':shelfId/sections/:sectionId', element: <WShelfScreen />},
    ],
  },
  {
    element: <Authorize featureGroup='V2Reception' />,
    children: [
      {
        path: '/wreceptions',
        element: <Authorize feature='V2ReceptionData' />,
        children: [
          {index: true, element: <WReceptionScreen />},
          {path: 'detail', element: <WReceptionDetail />},
        ],
      },
      {
        path: '/wreception-guests',
        element: (
          <Authorize feature='V2ReceptionGuest'>
            <WReceptionGuests />
          </Authorize>
        ),
      },
    ],
  },
  {
    path: '/access-control',
    element: <Authorize featureGroup='V2AccessControl' />,
    children: [
      {
        path: 'key-bundles',
        element: <Authorize feature='v2KeyBundles' />,
        children: [
          {index: true, element: <KeyBundle />},
          {path: 'detail', element: <KeyBundleDetail />},
        ],
      },
      {
        path: 'access-rights',
        element: <Authorize feature='v2AccessRights' />,
        children: [
          {index: true, element: <AccessRights />},
          {path: 'detail', element: <AccessRightsDetail />},
        ],
      },
      {
        path: 'fixed-passcodes',
        element: <Authorize feature='PasscodeGroups' />,
        children: [
          {index: true, element: <WFixedPasscodeScreen />},
          {path: ':id', element: <WFixedPasscodeScreen />},
        ],
      },
    ],
  },
  {
    path: '/keys/nfc',
    element: <Authorize featureGroup='V2SecurityCards' />,
    children: [
      {
        path: 'nfc-card-groups',
        element: <Authorize feature='v2SecurityCardGroups' />,
        children: [
          {index: true, element: <WNfcCardGroupScreen />},
          {path: 'detail', element: <WNfcCardGroupDetail />},
        ],
      },
      {
        path: 'temporary-use-groups',
        element: <Authorize feature='v2TemporaryCardGroups' />,
        children: [
          {index: true, element: <WNfcCardTemporaryGroupScreen />},
          {path: 'detail', element: <WNfcCardTemporaryGroupDetail />},
          {path: 'access-right/detail', element: <WNfcCardTemporaryAttachAccessRightDetail />},
        ],
      },
    ],
  },
  {
    path: '/signage',
    element: <Authorize featureGroup='V2Signage' />,
    children: [
      {
        path: 'employees/attendance',
        element: (
          <Authorize feature='signageEmployeeAttendance'>
            <SignageEmployeesAttendanceScreen />
          </Authorize>
        ),
      },
      {
        path: 'locationSearch',
        element: (
          <Authorize feature='signageCheckInInformation'>
            <SignageLocationSearchScreen />
          </Authorize>
        ),
      },
      {
        path: 'traffic-conditions',
        element: (
          <Authorize feature='signageTrafficCondition'>
            <SignageTrafficConditions />
          </Authorize>
        ),
      },
    ],
  },
  {
    path: '/data-import',
    element: <Authorize featureGroup='DataImport' feature='DataImport' />,
    children: [
      {index: true, element: <WDataImportScreen />},
      {path: 'contract', element: <ContractImportScreen />},
      {path: 'reservation', element: <ReservationImportScreen />},
      {path: 'detail', element: <WDataImportDetail />},
    ],
  },
  {
    path: '/events',
    element: <Authorize featureGroup='Events' />,
    children: [
      {
        path: 'participants/reservation',
        element: (
          <Authorize feature='eventReservationParticipants'>
            <EventParticipantReservationScreen />
          </Authorize>
        ),
      },
      {
        path: 'list/reservation',
        element: (
          <Authorize feature='eventReservationList'>
            <EventReservationListScreen />
          </Authorize>
        ),
      },
    ],
  },
  {
    path: '/for-bitkey',
    element: <Authorize featureGroup='ForBitkey' feature='ForBitkey' />,
    children: [
      {path: 'announcementManagement', element: <WAnnouncementManagementScreen />},
      {path: 'manualManagement', element: <WManualManagementScreen />},
    ],
  },
  {
    element: <Authorize featureGroup='Authority' />,
    children: [
      {
        path: '/authority',
        element: (
          <Authorize feature='authority'>
            <AuthorityScreen />
          </Authorize>
        ),
      },
      {
        path: '/settings/organization',
        element: (
          <Authorize feature='V2OrganizationSetting'>
            <WOrganizationSettingScreen />
          </Authorize>
        ),
      },
      {
        path: '/settings/notification',
        element: (
          <Authorize feature='NotificationSetting'>
            <WNotificationSettingScreen />
          </Authorize>
        ),
      },
    ],
  },
  {
    path: '/settings/mail-templates',
    element: (
      <Authorize featureGroup='V2MailTemplates' feature='V2MailTemplates'>
        <WMailTemplateScreen />
      </Authorize>
    ),
  },
  {
    path: '/settings/linkage-setting',
    element: (
      <Authorize featureGroup='LinkageSetting' feature='linkageSetting'>
        <WLinkageSettingScreen />
      </Authorize>
    ),
  },
  {
    // SSO連携でのログイン画面のURLパースの作り方がよくなくて、
    // URLをログイン画面にするとssoのログイン画面に遷移してしまうので一旦短縮
    path: '/settings/ss-setting',
    element: (
      <Authorize featureGroup='SSOSetting' feature='SSOSetting'>
        <WSSOSettingScreen />
      </Authorize>
    ),
  },
  {
    path: '/reservation/reservation-settings',
    element: <Authorize featureGroup='ReservationCalendar' feature='reservationSetting' />,
    children: [
      {index: true, element: <ReservationSetting />},
      {path: 'unavailable-time-settings', element: <WReservationUnavailableTimeSettingScreen />},
      {path: 'unavailable-time-settings/detail', element: <WReservationUnavailableTimeSettingDetail />},
    ],
  },
  {
    path: '/reception/reception-settings',
    element: (
      <Authorize featureGroup='V2Reception' feature='receptionSetting'>
        <WReceptionSettingScreen />
      </Authorize>
    ),
  },
  {
    path: '/reception/reception-setting-template',
    element: (
      <Authorize featureGroup='V2Reception' feature='receptionSettingTemplate'>
        <WReceptionSettingTemplateScreen />
      </Authorize>
    ),
  },
  {
    path: '/settings/attendance-setting',
    element: (
      <Authorize featureGroup='Attendance' feature='attendance'>
        <WAttendanceSettingScreen />
      </Authorize>
    ),
  },
  {
    path: '/settings/fm-dashboard',
    element: <Authorize featureGroup='FMDashboard' feature='FMDashboard' />,
    children: [
      {index: true, element: <FMDashboardSetting />},
      {path: 'satisfaction', element: <FMDashboardSatisfactionSetting />},
      {path: 'satisfaction/:satisfactionSettingId', element: <FMDashboardSatisfactionSetting />},
    ],
  },
  {
    path: '/manual',
    element: (
      <Authorize featureGroup='V2Manual' feature='V2Manual'>
        <WManualScreen />
      </Authorize>
    ),
  },
  {
    // ルート / はすでに TanStack Router へ移行済みだが, React Router の初期ルートとして用意.
    // これがないとすぐ下の catch-all ルートがマッチしてしまうため, ページ遷移時に / へ戻されてしまうことがある.
    path: '/',
    element: null,
  },
  {
    // 存在しないルートにアクセスされたら / へリダイレクトする.
    // ルートを追加するときはこれよりも上に追加してください.
    path: '/*',
    Component: () => {
      // React Router -> TanStack Router にページ遷移するときに動的なモジュールの読み込みが発生するなどして時間がかかると,
      // Router がアンマウントされるよりも前に履歴の同期が走るため, ここに到達して / へリダイレクトされてしまうことがある.
      // これを防ぐために TanStack Router が pending の間は null を返しておく.
      // FIXME: さっさと TanStack Router 移行終わらせたい
      const state = useRouterState();
      if (state.status === 'pending') {
        return null;
      }

      return <Navigate to='/' />;
    },
  },
];

/**
 * @deprecated ルーティングとメニューを分離する作業中です. ルートを追加したい場合は両方に追加してください
 */
export const routes: Route[] = [
  // アカウントセクション
  {
    type: 'withoutChildren',
    dictKey: 'wMypage',
    resource: {type: 'route', path: '/mypage', component: MypageScreen},
    icon: Icon.Account,
    featureGroup: FeatureGroup.V2MemberManagement,
    feature: Feature.Mypage,
  },
  {
    type: 'withoutChildren',
    dictKey: 'announcement',
    resource: {type: 'route', path: '/announcements', component: AnnouncementScreen},
    icon: Icon.Notice,
    featureGroup: FeatureGroup.Announcement,
    feature: Feature.Announcement,
  },
  {
    type: 'withoutChildren',
    dictKey: '',
    hide: () => true,
    resource: {type: 'route', path: '/announcements/detail', component: AnnouncementDetail},
    featureGroup: FeatureGroup.Announcement,
    feature: Feature.Announcement,
  },
  {
    type: 'withChildren',
    dictKey: 'reservations',
    icon: Icon.Calendar,
    children: [
      {
        dictKey: 'mySchedule',
        resource: {type: 'route', path: '/reservation-my-schedule', component: MySchedule},
        featureGroup: FeatureGroup.ReservationCalendar,
        feature: Feature.ReservationCalendarMySchedule,
      },
      {
        dictKey: 'reservationCalendar',
        resource: {type: 'route', path: '/reservation-calendar', component: ReservationSpaceCalendar},
        featureGroup: FeatureGroup.ReservationCalendar,
        feature: Feature.ReservationCalendar,
      },
      {
        dictKey: 'reservationThingCalendar',
        resource: {type: 'route', path: '/reservation-thing-calendar', component: ReservationThingCalendar},
        featureGroup: FeatureGroup.ReservationCalendar,
        feature: Feature.ReservationThingCalendar,
      },
      {
        dictKey: 'reservationLockerCalendar',
        resource: {type: 'route', path: '/lockers/reservation-calendar', component: ReservationLockerCalendar},
        featureGroup: FeatureGroup.V2Locker,
        feature: Feature.V2LockerReservationCalendar,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'visitApplication',
    icon: Icon.Send,
    children: [
      {
        dictKey: 'applicantTaskList',
        // 2025/01/07時点で京セラ様利用しか想定していないため、京セラ様のラキールURLをハードコーディングしています
        // 複数社で利用するときに初めて、URLを動的に変更する仕組みを入れる予定です
        resource: {
          type: 'link',
          url:
            import.meta.env.VITE_ENVIRONMENT === 'production'
              ? 'https://www.lc.lakeel.com/workflow/task/applicantList/?tenantCode=kyocera'
              : 'https://www.dev.lc.lakeel.com/workflow/task/applicantList/?tenantCode=kyocera',
        },
        icon: lazy(() => import('@/components/figma/icon/WIconExternal')),
        featureGroup: FeatureGroup.VisitApplication,
        feature: Feature.ApplicantTaskList,
      },
      {
        dictKey: 'approverTaskList',
        // 2025/01/07時点で京セラ様利用しか想定していないため、京セラ様のラキールURLをハードコーディングしています
        // 複数社で利用するときに初めて、URLを動的に変更する仕組みを入れる予定です
        resource: {
          type: 'link',
          url:
            import.meta.env.VITE_ENVIRONMENT === 'production'
              ? 'https://www.lc.lakeel.com/workflow/task/approverList/?tenantCode=kyocera'
              : 'https://www.dev.lc.lakeel.com/workflow/task/approverList/?tenantCode=kyocera',
        },
        icon: lazy(() => import('@/components/figma/icon/WIconExternal')),
        featureGroup: FeatureGroup.VisitApplication,
        feature: Feature.ApproverTaskList,
      },
      {
        dictKey: 'applicationFormList',
        // 2025/01/07時点で京セラ様利用しか想定していないため、京セラ様のラキールURLをハードコーディングしています
        // 複数社で利用するときに初めて、URLを動的に変更する仕組みを入れる予定です
        resource: {
          type: 'link',
          url:
            import.meta.env.VITE_ENVIRONMENT === 'production'
              ? 'https://www.lc.lakeel.com/workflow/application/formList/?tenantCode=kyocera'
              : 'https://www.dev.lc.lakeel.com/workflow/application/formList/?tenantCode=kyocera',
        },
        icon: lazy(() => import('@/components/figma/icon/WIconExternal')),
        featureGroup: FeatureGroup.VisitApplication,
        feature: Feature.ApplicationFormList,
      },
    ],
  },
  //「調べる」セクション
  // ビルテナントサービスがない場合はdivider付きのものを表示する
  {
    type: 'withChildren',
    dictKey: 'examine',
    icon: Icon.Search,
    divider: 'bottom',
    hide: featureGroup => featureGroup.includes('BuildingTenantService'),
    children: [
      {
        dictKey: 'trafficConditions',
        resource: {type: 'route', path: '/space/traffic-conditions', component: TrafficConditions},
        featureGroup: FeatureGroup.TrafficConditions,
        feature: Feature.TrafficConditions,
      },
      {
        dictKey: 'wLocationSearch',
        resource: {type: 'route', path: '/workspace-view/locationSearch', component: LocationSearchScreen},
        featureGroup: FeatureGroup.V2LocationSearch,
        feature: Feature.V2LocationSearch,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'examine',
    icon: Icon.Search,
    hide: featureGroup => !featureGroup.includes('BuildingTenantService'),
    children: [
      {
        dictKey: 'trafficConditions',
        resource: {type: 'route', path: '/space/traffic-conditions', component: TrafficConditions},
        featureGroup: FeatureGroup.TrafficConditions,
        feature: Feature.TrafficConditions,
      },
      {
        dictKey: 'wLocationSearch',
        resource: {type: 'route', path: '/workspace-view/locationSearch', component: LocationSearchScreen},
        featureGroup: FeatureGroup.V2LocationSearch,
        feature: Feature.V2LocationSearch,
      },
    ],
  },
  //ビルテナントサービス
  {
    type: 'withoutChildren',
    dictKey: 'buildingTenantService',
    divider: 'bottom',
    resource: {
      type: 'route',
      path: '/building-tenant-service',
    },
    icon: Icon.BuildingSupport,
    featureGroup: FeatureGroup.BuildingTenantService,
    feature: Feature.BuildingTenantService,
  },
  // サードプレイスセクション
  {
    type: 'withChildren',
    dictKey: 'contractManagement',
    groupKey: 'thirdPlace',
    icon: Icon.Contract,
    hide: featureGroup => !featureGroup.includes('ThirdPlace'),
    children: [
      {
        dictKey: '',
        resource: {type: 'route', path: '/third-place/contract-plan/release', component: WContractPlanRelease},
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlace,
      },
      {
        dictKey: 'thirdPlaceContractCreate',
        resource: {type: 'route', path: '/third-place/contracts/create', component: WContractCreateScreen},
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceContract,
      },
      {
        dictKey: 'thirdPlaceContract',
        resource: {type: 'route', path: '/third-place/contracts', component: WCoworkingContractScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceContract,
      },
      {
        dictKey: 'thirdPlaceContract',
        resource: {type: 'route', path: '/third-place/contracts/detail', component: WCoworkingContractDetail},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceContract,
        hide: () => true,
      },
      {
        dictKey: 'thirdPlaceContractRenew',
        resource: {type: 'route', path: '/third-place/contracts/renew', component: WContractRenewScreen},
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceContract,
      },
      {
        dictKey: 'buildingOwnerContract',
        resource: {type: 'route', path: '/multi-tenant-owner/contracts', component: MultiTenantContractsScreen},
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContract,
        hide: featureGroup => !(featureGroup.includes('ThirdPlace') && featureGroup.includes('V2MultiTenantOwner')),
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/multi-tenant-owner/contracts/detail',
          component: MultiTenantContractsDetailScreen,
        },
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContract,
        hide: () => true,
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/multi-tenant-owner/contracts/create',
          component: MultiTenantContractsCreateScreen,
        },
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContract,
        hide: () => true,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'memberAndCustomerManagement',
    icon: Icon.AreaType.TenantArea,
    groupKey: 'thirdPlace',
    children: [
      {
        dictKey: 'thirdPlaceGuest',
        resource: {type: 'route', path: '/third-place/guest', component: WCoworkingGuestScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceGuest,
      },
      {
        dictKey: 'thirdPlaceGuest',
        resource: {type: 'route', path: '/third-place/guest/detail', component: WCoworkingGuestDetail},
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceGuest,
      },
      {
        dictKey: 'thirdPlaceGuest',
        resource: {type: 'route', path: '/third-place/guest/bill/detail', component: WCoworkingGuestBillDetail},
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceGuest,
      },
      {
        dictKey: 'thirdPlaceCustomer',
        resource: {type: 'route', path: '/third-place/tenant', component: TPCustomerScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceCustomer,
      },
      {
        dictKey: 'thirdPlaceCustomer',
        resource: {type: 'route', path: '/third-place/tenant/detail', component: WCoworkingTenantDetail},
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceCustomer,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'billingManagement',
    groupKey: 'thirdPlace',
    icon: Icon.Invoice,
    children: [
      {
        dictKey: 'v2BillingInfo',
        resource: {type: 'route', path: '/third-place/billing/screen', component: WCoworkingBillingScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceBilling,
      },
      {
        dictKey: 'v2BillingInfo',
        resource: {type: 'route', path: '/third-place/billing/detail', component: WCoworkingBillingDetail},
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceBilling,
      },
      {
        dictKey: 'v2ReceivedMoneyInfo',
        resource: {
          type: 'route',
          path: '/third-place/received-money/screen',
          component: WCoworkingReceivedMoneyManagementScreen,
        },
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceReceivedMoney,
      },
      {
        dictKey: 'v2ReceivedMoneyInfo',
        resource: {
          type: 'route',
          path: '/third-place/received-money/detail',
          component: WCoworkingReceivedMoneyDetail,
        },
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceReceivedMoney,
      },
      {
        dictKey: 'v2RefundManagement',
        resource: {
          type: 'route',
          path: '/third-place/refund/screen',
          component: WCoworkingRefundManagementScreen,
        },
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceRefund,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'reservationManagement',
    groupKey: 'thirdPlace',
    icon: Icon.HistoryCheck,
    children: [
      {
        dictKey: 'usageHistory',
        resource: {
          type: 'route',
          path: '/third-place/reservation',
          component: WReservationThirdPlaceCustomerMeetingRoomScreen,
        },
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ReservationLog,
      },
      {
        dictKey: 'usageHistory',
        resource: {
          type: 'route',
          path: '/third-place/reservation/detail',
          component: WReservationThirdPlaceCustomerMeetingRoomDetailScreen,
        },
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ReservationLog,
        hide: () => true,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'plansAndOptions',
    groupKey: 'thirdPlace',
    icon: Icon.Plan,
    hide: featureGroup => !featureGroup.includes('ThirdPlace'),
    children: [
      {
        dictKey: 'thirdPlaceContractPlans',
        resource: {type: 'route', path: '/third-place/contract-plans', component: WCoworkingContractPlanScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceContractPlans,
      },
      {
        dictKey: 'thirdPlaceContractPlans',
        resource: {
          type: 'route',
          path: '/third-place/contract-plans/detail',
          component: WCoworkingContractPlanDetail,
        },
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceContractPlans,
        hide: () => true,
      },
      {
        dictKey: 'buildingOwnerContractPlans',
        resource: {
          type: 'route',
          path: '/multi-tenant-owner/contract-plans',
          component: MultiTenantContractPlansScreen,
        },
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContractPlan,
        hide: featureGroup => !(featureGroup.includes('ThirdPlace') && featureGroup.includes('V2MultiTenantOwner')),
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/multi-tenant-owner/contract-plans/detail',
          component: MultiTenantContractPlansDetailScreen,
        },
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContractPlan,
        hide: () => true,
      },
      {
        dictKey: 'optionService',
        resource: {type: 'route', path: '/third-place/options', component: WCoworkingOptionScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceOptions,
      },
      {
        dictKey: 'optionService',
        resource: {type: 'route', path: '/third-place/options/detail', component: WCoworkingOptionDetail},
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceOptions,
      },
      {
        dictKey: 'dropInPlans',
        resource: {type: 'route', path: '/third-place/drop-in/plans', component: DropInPlansScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.DropInPlans,
      },
      {
        dictKey: 'dropInPlans',
        resource: {type: 'route', path: '/third-place/drop-in/plans/detail', component: DropInPlanDetailScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.DropInPlans,
        hide: () => true,
      },
      {
        dictKey: 'dropInPricePlans',
        resource: {type: 'route', path: '/third-place/drop-in/price-plans', component: DropInPricePlansScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.DropInPricePlans,
      },
      {
        dictKey: 'dropInPricePlans',
        resource: {
          type: 'route',
          path: '/third-place/drop-in/price-plans/detail',
          component: DropInPricePlansDetailScreen,
        },
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.DropInPricePlans,
        hide: () => true,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'thirdPlaceSetting',
    groupKey: 'thirdPlace',
    icon: Icon.ThirdPlace,
    children: [
      {
        dictKey: 'thirdPlaceUpdateRules',
        resource: {type: 'route', path: '/third-place/update-rules', component: WCoworkingUpdateRulesScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceUpdateRules,
      },
      {
        dictKey: 'thirdPlaceUpdateRules',
        resource: {
          type: 'route',
          path: '/third-place/update-rules/detail',
          component: WCoworkingUpdateRulesDetail,
        },
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceUpdateRules,
      },
      {
        dictKey: 'thirdPlaceCancelRules',
        resource: {type: 'route', path: '/third-place/cancel-rules', component: WCoworkingCancelRulesScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceCancelRules,
      },
      {
        dictKey: 'thirdPlaceCancelRules',
        resource: {
          type: 'route',
          path: '/third-place/cancel-rules/detail',
          component: WCoworkingCancelRulesDetail,
        },
        hide: () => true,
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceCancelRules,
      },
      {
        dictKey: 'thirdPlaceClaimCycles',
        resource: {type: 'route', path: '/third-place/claim-cycles', component: WCoworkingClaimCycleScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceClaimCycles,
      },
      {
        dictKey: 'thirdPlaceInvoice',
        resource: {type: 'route', path: '/third-place/invoice-setting', component: WInvoiceSettingScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlaceInvoice,
      },
      {
        dictKey: 'thirdPlacePaymentMethod',
        resource: {
          type: 'route',
          path: '/third-place/payment-method',
          component: WCoworkingPaymentMethodScreen,
        },
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlacePaymentMethod,
      },
      {
        dictKey: 'thirdPlacePassSiteSetting',
        resource: {type: 'route', path: '/third-place/workhubPassSetting', component: WorkpassSettingScreen},
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.ThirdPlacePassSite,
      },
      {
        dictKey: 'invitationMailSetting',
        resource: {
          type: 'route',
          path: '/settings/invitationMailSetting',
          component: WInvitationMailSettingScreen,
        },
        featureGroup: FeatureGroup.ThirdPlace,
        feature: Feature.InviteMailSettingManager,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'buildingTenantManagement',
    groupKey: 'thirdPlace',
    icon: Icon.BuildingOwner,
    hide: featureGroup => !featureGroup.includes('ThirdPlace'),
    children: [
      {
        dictKey: 'buildingTenantServiceManagement',
        resource: {
          type: 'route',
          path: '/building-tenant-management/building-tenant-service',
        },
        featureGroup: FeatureGroup.BuildingTenantManagement,
        feature: Feature.BuildingTenantServiceManagement,
      },
      {
        dictKey: 'buildingAnnouncementManagement',
        resource: {
          type: 'route',
          path: '/building-tenant-management/announcement',
        },
        featureGroup: FeatureGroup.BuildingTenantManagement,
        feature: Feature.BuildingAnnouncementManagement,
      },
      {
        dictKey: 'buildingAnnouncementManagement',
        resource: {
          type: 'route',
          path: '/building-tenant-management/announcement/create',
        },
        hide: () => true,
        featureGroup: FeatureGroup.BuildingTenantManagement,
        feature: Feature.BuildingAnnouncementManagement,
      },
    ],
  },
  // ビルオーナー
  {
    type: 'withChildren',
    dictKey: 'buildingOwnerContractManagement',
    groupKey: 'buildingOwner',
    icon: Icon.Contract,
    hide: featureGroup => featureGroup.includes('ThirdPlace'),
    children: [
      {
        dictKey: 'buildingOwnerContract',
        resource: {type: 'route', path: '/multi-tenant-owner/contracts', component: MultiTenantContractsScreen},
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContract,
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/multi-tenant-owner/contracts/detail',
          component: MultiTenantContractsDetailScreen,
        },
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContract,
        hide: () => true,
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/multi-tenant-owner/contracts/create',
          component: MultiTenantContractsCreateScreen,
        },
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContract,
        hide: () => true,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'buildingOwnerMember',
    icon: Icon.AreaType.TenantArea,
    groupKey: 'buildingOwner',
    hide: featureGroup => featureGroup.includes('ThirdPlace'),
    children: [
      {
        dictKey: 'thirdPlaceGuest',
        resource: {type: 'route', path: '/third-place/guest', component: WCoworkingGuestScreen},
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.ThirdPlaceGuest,
      },
      {
        dictKey: 'thirdPlaceGuest',
        resource: {type: 'route', path: '/third-place/guest/detail', component: WCoworkingGuestDetail},
        hide: () => true,
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.ThirdPlaceGuest,
      },
      {
        dictKey: 'thirdPlaceGuest',
        resource: {type: 'route', path: '/third-place/guest/bill/detail', component: WCoworkingGuestBillDetail},
        hide: () => true,
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.ThirdPlaceGuest,
      },
      {
        dictKey: 'thirdPlaceCustomer',
        resource: {type: 'route', path: '/third-place/tenant', component: TPCustomerScreen},
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.ThirdPlaceCustomer,
      },
      {
        dictKey: 'thirdPlaceCustomer',
        resource: {type: 'route', path: '/third-place/tenant/detail', component: WCoworkingTenantDetail},
        hide: () => true,
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.ThirdPlaceCustomer,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'buildingOwnerPlanAndOptions',
    groupKey: 'buildingOwner',
    icon: Icon.Plan,
    hide: featureGroup => featureGroup.includes('ThirdPlace'),
    children: [
      {
        dictKey: 'buildingOwnerContractPlans',
        resource: {
          type: 'route',
          path: '/multi-tenant-owner/contract-plans',
          component: MultiTenantContractPlansScreen,
        },
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContractPlan,
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/multi-tenant-owner/contract-plans/detail',
          component: MultiTenantContractPlansDetailScreen,
        },
        featureGroup: FeatureGroup.V2MultiTenantOwner,
        feature: Feature.V2MultiTenantOwnerContractPlan,
        hide: () => true,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'buildingTenantManagement',
    groupKey: 'buildingOwner',
    icon: Icon.BuildingOwner,
    hide: featureGroup => featureGroup.includes('ThirdPlace'),
    children: [
      {
        dictKey: 'buildingTenantServiceManagement',
        resource: {
          type: 'route',
          path: '/building-tenant-management/building-tenant-service',
        },
        featureGroup: FeatureGroup.BuildingTenantManagement,
        feature: Feature.BuildingTenantServiceManagement,
      },
      {
        dictKey: 'buildingAnnouncementManagement',
        resource: {
          type: 'route',
          path: '/building-tenant-management/announcement',
        },
        featureGroup: FeatureGroup.BuildingTenantManagement,
        feature: Feature.BuildingAnnouncementManagement,
      },
      {
        dictKey: 'buildingAnnouncementManagement',
        resource: {
          type: 'route',
          path: '/building-tenant-management/announcement/create',
        },
        hide: () => true,
        featureGroup: FeatureGroup.BuildingTenantManagement,
        feature: Feature.BuildingAnnouncementManagement,
      },
    ],
  },
  // 申請
  {
    type: 'withChildren',
    dictKey: 'wApplication',
    groupKey: 'application',
    icon: Icon.Request,
    children: [
      {
        dictKey: 'wApprovalTray',
        resource: {type: 'route', path: '/application/approval-tray', component: WApprovalTrayScreen},
        featureGroup: FeatureGroup.Application,
        feature: Feature.Application,
      },
      {
        dictKey: 'wApprovalTray',
        resource: {
          type: 'route',
          path: '/application/approval-tray/detail',
          component: WApplicationDetailScreen,
        },
        featureGroup: FeatureGroup.Application,
        feature: Feature.Application,
        hide: () => true,
      },
    ],
  },
  // モニタリング
  {
    type: 'withChildren',
    dictKey: 'reports',
    icon: Icon.View.BarChartVertical,
    groupKey: 'monitoring',
    children: [
      // {
      //   dictKey: 'spaceUsageReport2',
      //   path: '/workspace-view/space-usage-report-2',
      //   component: SpaceUsageReportScreen,
      //   featureGroup: FeatureGroup.FMDashboard,
      //   feature: Feature.FMDashboard,
      // },
      {
        dictKey: 'fmDashboard',
        resource: {type: 'route', path: '/workspace-view/fm-dashboard', component: FMDashboard},
        featureGroup: FeatureGroup.FMDashboard,
        feature: Feature.FMDashboard,
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/workspace-view/fm-dashboard/satisfaction',
          component: FMDashboardSatisfactionList,
        },
        featureGroup: FeatureGroup.FMDashboard,
        feature: Feature.FMDashboard,
        hide: () => true,
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/workspace-view/fm-dashboard/satisfaction/:spaceId',
          component: SpaceUsageReportSatisfactionDetailBySpaceIdScreen,
        },
        featureGroup: FeatureGroup.FMDashboard,
        feature: Feature.FMDashboard,
        hide: () => true,
      },
      {
        dictKey: 'wEmployeesAttendance',
        resource: {type: 'route', path: '/workspace-view/attendance', component: EmployeesAttendanceScreen},
        featureGroup: FeatureGroup.V2EmployeesAttendanceReport,
        feature: Feature.V2EmployeesAttendance,
      },
      {
        dictKey: 'securityDeviceAlertInquiry',
        resource: {
          type: 'route',
          path: '/security-devices/alert-inquiry',
          component: WSecurityDeviceAlertInquiryScreen,
        },
        featureGroup: FeatureGroup.V2SecurityDevices,
        feature: Feature.V2SecurityDeviceAlert,
      },
      {
        dictKey: 'alertAntiPassBack',
        resource: {
          type: 'route',
          path: '/workspace-view/alert-anti-pass-back',
          component: AlertAntiPassBackScreen,
        },
        featureGroup: FeatureGroup.AntiPassBack,
        feature: Feature.AntiPassBackAlert,
      },
    ],
  },

  {
    type: 'withChildren',
    dictKey: 'logAndHistory',
    icon: Icon.Preview,
    groupKey: 'monitoring',
    children: [
      {
        dictKey: 'sendMailJob',
        resource: {type: 'route', path: '/send-mail-job', component: WSendMailJobScreen},
        featureGroup: FeatureGroup.Report,
        feature: Feature.SendMailJob,
      },
      {
        dictKey: 'sendMailJob',
        resource: {type: 'route', path: '/send-mail-job/detail', component: WSendMailJobDetailScreen},
        hide: () => true,
        featureGroup: FeatureGroup.Report,
        feature: Feature.SendMailJob,
      },
      {
        dictKey: 'keyAndTickets',
        resource: {type: 'route', path: '/system-reports/key-tickets', component: WKeyAndTicketsScreen},
        featureGroup: FeatureGroup.Report,
        feature: Feature.AppKeys,
      },
      {
        dictKey: 'faceRecognitionStatus',
        resource: {
          type: 'route',
          path: '/system-reports/face-recognition-status',
          component: WFaceRecognitionStatusScreen,
        },
        featureGroup: FeatureGroup.Report,
        feature: Feature.FaceRecognitionStatus,
      },
      {
        dictKey: 'wLockAndUnlockLog',
        resource: {type: 'route', path: '/lock-unlock', component: WLockAndUnlockScreen},
        featureGroup: FeatureGroup.V2Spaces,
        feature: Feature.UnlockLog,
      },
      {
        dictKey: 'reservationHistory',
        resource: {type: 'route', path: '/reservation-history', component: WReservationListScreen},
        featureGroup: FeatureGroup.V2Spaces,
        feature: Feature.ReservationLog,
      },
      {
        dictKey: 'reservationHistory',
        resource: {type: 'route', path: '/reservation-history/detail', component: WReservationHistoryDetail},
        featureGroup: FeatureGroup.V2Spaces,
        feature: Feature.ReservationLog,
        hide: () => true,
      },
      {
        dictKey: 'outlookSyncHistories',
        resource: {type: 'route', path: '/outlook-sync-histories', component: OutlookSyncHistories},
        featureGroup: FeatureGroup.OutlookReservationSyncHistories,
        feature: Feature.OutlookReservationSyncHistories,
      },
      {
        dictKey: 'outlookSyncHistories',
        resource: {type: 'route', path: '/outlook-sync-histories/:id', component: OutlookSyncHistoriesDetail},
        featureGroup: FeatureGroup.OutlookReservationSyncHistories,
        feature: Feature.OutlookReservationSyncHistories,
        hide: () => true,
      },
      {
        dictKey: 'googleCalendarSyncHistories',
        resource: {type: 'route', path: '/google-calendar-sync-histories', component: GoogleCalendarSyncHistories},
        featureGroup: FeatureGroup.GoogleCalendarSync,
        feature: Feature.GoogleCalendarSyncHistories,
      },
      {
        dictKey: 'googleCalendarSyncHistories',
        resource: {
          type: 'route',
          path: '/google-calendar-sync-histories/:id',
          component: GoogleCalendarSyncHistoriesDetail,
        },
        featureGroup: FeatureGroup.GoogleCalendarSync,
        feature: Feature.GoogleCalendarSyncHistories,
        hide: () => true,
      },
    ],
  },
  // ユーザー
  {
    type: 'withChildren',
    dictKey: 'employeeManagement',
    icon: Icon.UserType.Employee,
    groupKey: 'user',
    children: [
      {
        dictKey: 'employees',
        resource: {type: 'route', path: '/people', component: WPeopleScreen},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2Members,
      },
      {
        dictKey: 'employees',
        resource: {type: 'route', path: '/people/detail', component: WPeopleDetail},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2Members,
        hide: () => true,
      },
      {
        dictKey: 'wDepartment',
        resource: {type: 'route', path: '/departments', component: WDepartmentScreen},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2OfficeDepartments,
      },
      {
        dictKey: 'wDepartment',
        resource: {type: 'route', path: '/departments/detail', component: WUserGroupsDetail},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2OfficeDepartments,
        hide: () => true,
      },
      {
        dictKey: 'wPosition',
        resource: {type: 'route', path: '/positions', component: WPositionScreen},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2Positions,
      },
      {
        dictKey: 'wPosition',
        resource: {type: 'route', path: '/positions/detail', component: WUserGroupsDetail},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2Positions,
        hide: () => true,
      },
      {
        dictKey: 'wEmploymentStatus',
        resource: {type: 'route', path: '/employment-status', component: WEmploymentStatusScreen},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2EmploymentStatuses,
      },
      {
        dictKey: 'wEmploymentStatus',
        resource: {type: 'route', path: '/employment-status/detail', component: WUserGroupsDetail},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2EmploymentStatuses,
        hide: () => true,
      },
      {
        dictKey: 'wUserGroup',
        resource: {type: 'route', path: '/user-groups', component: WUserGroupsScreen},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2EmployeeGroupSettings,
      },
      {
        dictKey: 'wUserGroup',
        resource: {type: 'route', path: '/user-groups/detail', component: WUserGroupsDetail},
        featureGroup: FeatureGroup.V2MemberManagement,
        feature: Feature.V2EmployeeGroupSettings,
        hide: () => true,
      },
      {
        dictKey: 'wFaceRegistration',
        resource: {type: 'route', path: '/face-registration', component: WFaceRegistrationScreen},
        featureGroup: FeatureGroup.FaceRegistration,
        feature: Feature.FaceRegistration,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'guestManagement',
    icon: Icon.UserType.Guest,
    groupKey: 'user',
    children: [
      {
        dictKey: 'wGuestInformation',
        resource: {type: 'route', path: '/visitors', component: WGuestScreen},
        featureGroup: FeatureGroup.V2VisitorManagement,
        feature: Feature.V2Visitors,
      },
      {
        dictKey: 'wGuestDetail',
        resource: {type: 'route', path: '/visitors/detail', component: WGuestDetail},
        featureGroup: FeatureGroup.V2VisitorManagement,
        feature: Feature.V2Visitors,
        hide: () => true,
      },
      {
        dictKey: 'wGuestGroup',
        resource: {type: 'route', path: '/visitor-groups', component: WGuestGroupScreen},
        featureGroup: FeatureGroup.V2VisitorGroupManagement,
        feature: Feature.V2Visitors,
      },
      {
        dictKey: 'wCustomerInformation',
        resource: {type: 'route', path: '/customers', component: WCustomerScreen},
        featureGroup: FeatureGroup.V2CustomerManagement,
        feature: Feature.V2Customers,
      },
      {
        dictKey: 'wCustomerDetail',
        resource: {type: 'route', path: '/customers/detail', component: WCustomerDetail},
        featureGroup: FeatureGroup.V2CustomerManagement,
        feature: Feature.V2Customers,
        hide: () => true,
      },
      {
        dictKey: 'wVendorInformation',
        resource: {type: 'route', path: '/vendors', component: WVendorScreen},
        featureGroup: FeatureGroup.V2CastManagement,
        feature: Feature.V2Casts,
      },
      {
        dictKey: 'wVendorDetail',
        resource: {type: 'route', path: '/vendors/detail', component: WVendorDetail},
        featureGroup: FeatureGroup.V2CastManagement,
        feature: Feature.V2Casts, // TODO 書き換え
        hide: () => true,
      },
      {
        dictKey: 'wVendorGroup',
        resource: {type: 'route', path: '/vendor-groups', component: WVendorGroupScreen},
        featureGroup: FeatureGroup.V2CastManagement,
        feature: Feature.V2CastGroups, // TODO 書き換え
      },
    ],
  },
  // スペース
  {
    type: 'withChildren',
    dictKey: 'wOfficeBaseManagement',
    icon: Icon.Space,
    groupKey: 'spaceAndDevice',
    children: [
      {
        dictKey: 'wRegionInformation',
        resource: {type: 'route', path: '/regions', component: WRegionScreen},
        featureGroup: FeatureGroup.V2Spaces,
        feature: Feature.V2Regions,
      },
      {
        dictKey: 'wBuildingInformation',
        resource: {type: 'route', path: '/buildings', component: WBuildingScreen},
        featureGroup: FeatureGroup.V2Spaces,
        feature: Feature.V2Buildings,
      },
      {
        dictKey: 'wFloorInformation',
        resource: {type: 'route', path: '/floors', component: WFloorScreen},
        featureGroup: FeatureGroup.V2Spaces,
        feature: Feature.V2Floors,
      },
      {
        dictKey: 'wArea',
        resource: {type: 'route', path: '/areas', component: WAreaScreen},
        featureGroup: FeatureGroup.V2Spaces,
        feature: Feature.V2Areas,
      },
      {
        dictKey: 'wArea',
        resource: {type: 'route', path: '/areas/detail', component: WAreaDetail},
        featureGroup: FeatureGroup.V2Spaces,
        feature: Feature.V2Areas,
        hide: () => true,
      },
      {
        dictKey: 'wWorkBooth',
        resource: {type: 'route', path: '/work-booths', component: WWorkBoothScreen},
        featureGroup: FeatureGroup.WorkBooth,
        feature: Feature.WorkBooth,
      },
      {
        dictKey: 'wWorkBooth',
        resource: {type: 'route', path: '/work-booths/detail', component: WAreaDetail},
        featureGroup: FeatureGroup.WorkBooth,
        feature: Feature.WorkBooth,
        hide: () => true,
      },
      {
        dictKey: 'wWorkspot',
        resource: {type: 'route', path: '/workspots', component: WWorkspotsScreen},
        featureGroup: FeatureGroup.V2Workspot,
        feature: Feature.V2Workspots,
      },
      {
        dictKey: 'wWorkspot',
        resource: {type: 'route', path: '/workspots/detail', component: WWorkspotDetailScreen},
        featureGroup: FeatureGroup.V2Workspot,
        feature: Feature.V2Workspots,
        hide: () => true,
      },
      {
        dictKey: 'antiPassBack',
        resource: {type: 'route', path: '/anti-pass-back', component: AntiPassBackScreen},
        featureGroup: FeatureGroup.AntiPassBack,
        feature: Feature.AntiPassBackArea,
      },
      {
        dictKey: 'antiPassBack',
        resource: {type: 'route', path: '/anti-pass-back/:settingId', component: AntiPassBackScreen},
        featureGroup: FeatureGroup.AntiPassBack,
        feature: Feature.AntiPassBackArea,
        hide: () => true,
      },
      {
        dictKey: 'layout',
        resource: {type: 'route', path: '/layout'},
        featureGroup: FeatureGroup.V2Layouts,
        feature: Feature.V2Layouts,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'deviceAndEquipmentManagement',
    icon: Icon.Equipment,
    groupKey: 'spaceAndDevice',
    children: [
      {
        dictKey: 'securityDevice',
        resource: {type: 'route', path: '/security-devices', component: WSecurityDeviceScreen},
        featureGroup: FeatureGroup.V2SecurityDevices,
        feature: Feature.V2SecurityDevices,
      },
      {
        dictKey: '',
        resource: {type: 'route', path: '/security-devices/detail', component: WSecurityDeviceDetail},
        featureGroup: FeatureGroup.V2SecurityDevices,
        feature: Feature.V2SecurityDevices,
        hide: () => true,
      },
      {
        dictKey: 'wEquipment',
        resource: {type: 'route', path: '/equipments', component: WEquipmentScreen},
        featureGroup: FeatureGroup.V2Equipment,
        feature: Feature.V2Equipments,
      },
      {
        dictKey: 'equipments',
        resource: {type: 'route', path: '/equipments/detail', component: WEquipmentDetail},
        featureGroup: FeatureGroup.V2Equipment,
        feature: Feature.V2Equipments,
        hide: () => true,
      },
      {
        dictKey: 'wLockerInfo',
        resource: {type: 'route', path: '/lockers', component: WLockerScreen},
        featureGroup: FeatureGroup.V2Locker,
        feature: Feature.V2Lockers,
      },
      {
        dictKey: 'wLocker',
        resource: {type: 'route', path: '/lockers/detail', component: WLockerDetailScreen},
        featureGroup: FeatureGroup.V2Locker,
        feature: Feature.V2Lockers,
        hide: () => true,
      },
      {
        dictKey: 'wShelf',
        resource: {type: 'route', path: '/shelves', component: WShelfScreen},
        featureGroup: FeatureGroup.Shelf,
        feature: Feature.Shelves,
      },
      {
        dictKey: 'wShelf',
        resource: {type: 'route', path: '/shelves/:shelfId', component: WShelfScreen},
        featureGroup: FeatureGroup.Shelf,
        feature: Feature.Shelves,
        hide: () => true,
      },
      {
        dictKey: 'wShelf',
        resource: {type: 'route', path: '/shelves/:shelfId/sections/:sectionId', component: WShelfScreen},
        featureGroup: FeatureGroup.Shelf,
        feature: Feature.Shelves,
        hide: () => true,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'reception',
    icon: Icon.Reception,
    groupKey: 'spaceAndDevice',
    children: [
      {
        dictKey: 'receptionData',
        resource: {type: 'route', path: '/wreceptions', component: WReceptionScreen},
        featureGroup: FeatureGroup.V2Reception,
        feature: Feature.V2ReceptionData,
      },
      {
        dictKey: 'receptionDetail',
        resource: {type: 'route', path: '/wreceptions/detail', component: WReceptionDetail},
        featureGroup: FeatureGroup.V2Reception,
        feature: Feature.V2ReceptionData,
        hide: () => true,
      },
      {
        dictKey: 'wreceptionGuests',
        resource: {type: 'route', path: '/wreception-guests', component: WReceptionGuests},
        featureGroup: FeatureGroup.V2Reception,
        feature: Feature.V2ReceptionGuest,
      },
    ],
  },
  // アクセスセキュリティ
  {
    type: 'withChildren',
    dictKey: 'keyAndAuthenticationManagement',
    icon: Icon.Key,
    groupKey: 'accessSecurity',
    children: [
      {
        dictKey: 'keyBundle',
        resource: {type: 'route', path: '/access-control/key-bundles', component: KeyBundle},
        featureGroup: FeatureGroup.V2AccessControl,
        feature: Feature.V2KeyBundles,
      },
      {
        dictKey: 'keyBundle',
        resource: {type: 'route', path: '/access-control/key-bundles/detail', component: KeyBundleDetail},
        featureGroup: FeatureGroup.V2AccessControl,
        feature: Feature.V2KeyBundles,
        hide: () => true,
      },
      {
        dictKey: 'accessRights',
        resource: {type: 'route', path: '/access-control/access-rights', component: AccessRights},
        featureGroup: FeatureGroup.V2AccessControl,
        feature: Feature.V2AccessRights,
      },
      {
        dictKey: 'accessRights',
        resource: {type: 'route', path: '/access-control/access-rights/detail', component: AccessRightsDetail},
        featureGroup: FeatureGroup.V2AccessControl,
        feature: Feature.V2AccessRights,
        hide: () => true,
      },
      {
        dictKey: 'fixedPasscodes',
        // todo 仮にワンタイムパスコードをサイドバーに表示することになった場合、pathやcomponentの命名を変更する
        resource: {type: 'route', path: '/access-control/fixed-passcodes', component: WFixedPasscodeScreen},
        featureGroup: FeatureGroup.V2AccessControl,
        feature: Feature.PasscodeGroups,
      },
      {
        dictKey: '',
        hide: () => true,
        resource: {type: 'route', path: '/access-control/fixed-passcodes/:id', component: WFixedPasscodeScreen},
        featureGroup: FeatureGroup.V2AccessControl,
        feature: Feature.PasscodeGroups,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'wSecurityCardManagement',
    icon: Icon.Card,
    groupKey: 'accessSecurity',
    children: [
      {
        dictKey: 'wSecurityCardInfo',
        resource: {type: 'route', path: '/nfc-cards'},
        feature: Feature.V2SecurityCards,
        featureGroup: FeatureGroup.V2SecurityCards,
      },
      {
        dictKey: 'wSecurityCardGroup',
        resource: {type: 'route', path: '/keys/nfc/nfc-card-groups', component: WNfcCardGroupScreen},
        featureGroup: FeatureGroup.V2SecurityCardGroups,
        feature: Feature.V2SecurityCardGroups,
      },
      {
        dictKey: '',
        resource: {type: 'route', path: '/keys/nfc/nfc-card-groups/detail', component: WNfcCardGroupDetail},
        featureGroup: FeatureGroup.V2SecurityCardGroups,
        feature: Feature.V2SecurityCardGroups,
        hide: () => true,
      },
      {
        dictKey: 'wTemporaryCardGroup',
        resource: {
          type: 'route',
          path: '/keys/nfc/temporary-use-groups',
          component: WNfcCardTemporaryGroupScreen,
        },
        featureGroup: FeatureGroup.V2SecurityCards,
        feature: Feature.V2TemporaryCardGroups,
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/keys/nfc/temporary-use-groups/detail',
          component: WNfcCardTemporaryGroupDetail,
        },
        featureGroup: FeatureGroup.V2SecurityCards,
        feature: Feature.V2TemporaryCardGroups,
        hide: () => true,
      },
      {
        dictKey: '',
        resource: {
          type: 'route',
          path: '/keys/nfc/temporary-use-groups/access-right/detail',
          component: WNfcCardTemporaryAttachAccessRightDetail,
        },
        featureGroup: FeatureGroup.V2SecurityCards,
        feature: Feature.V2TemporaryCardGroups,
        hide: () => true,
      },
    ],
  },

  // その他
  {
    type: 'withChildren',
    dictKey: 'signage',
    icon: Icon.AreaType.Workspace,
    groupKey: 'other',
    children: [
      {
        dictKey: 'wEmployeesAttendance',
        resource: {
          type: 'route',
          path: '/signage/employees/attendance',
          component: SignageEmployeesAttendanceScreen,
        },
        featureGroup: FeatureGroup.V2Signage,
        feature: Feature.SignageEmployeeAttendance,
      },
      {
        dictKey: 'wCheckinInformation',
        resource: {type: 'route', path: '/signage/locationSearch', component: SignageLocationSearchScreen},
        featureGroup: FeatureGroup.V2Signage,
        feature: Feature.SignageCheckInInformation,
      },
      {
        dictKey: 'trafficConditions',
        resource: {type: 'route', path: '/signage/traffic-conditions', component: SignageTrafficConditions},
        featureGroup: FeatureGroup.V2Signage,
        feature: Feature.SignageTrafficCondition,
      },
    ],
  },
  {
    type: 'withoutChildren',
    dictKey: 'dataImport',
    groupKey: 'other',
    icon: Icon.Import,
    resource: {type: 'route', path: '/data-import', component: WDataImportScreen},
    featureGroup: FeatureGroup.DataImport,
    feature: Feature.DataImport,
  },
  {
    type: 'withChildren',
    dictKey: 'events',
    icon: lazy(() => import('@mui/icons-material/Event')),
    groupKey: 'other',
    children: [
      {
        dictKey: 'eventParticipantsReservationScreen',
        resource: {
          type: 'route',
          path: '/events/participants/reservation',
          component: EventParticipantReservationScreen,
        },
        featureGroup: FeatureGroup.Events,
        feature: Feature.EventReservationParticipants,
      },
      {
        dictKey: 'eventListReservationScreen',
        resource: {type: 'route', path: '/events/list/reservation', component: EventReservationListScreen},
        featureGroup: FeatureGroup.Events,
        feature: Feature.EventReservationList,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'wForBitkey',
    groupKey: 'other',
    hide: () => true,
    children: [
      {
        hide: () => true,
        dictKey: 'wAnnouncementManagement',
        resource: {
          type: 'route',
          path: '/for-bitkey/announcementManagement',
          component: WAnnouncementManagementScreen,
        },
        featureGroup: FeatureGroup.ForBitkey,
        feature: Feature.ForBitkey,
      },
      {
        hide: () => true,
        dictKey: 'manualManagement',
        resource: {type: 'route', path: '/for-bitkey/manualManagement', component: WManualManagementScreen},
        featureGroup: FeatureGroup.ForBitkey,
        feature: Feature.ForBitkey,
      },
    ],
  },
  {
    type: 'withoutChildren',
    dictKey: '',
    hide: () => true,
    featureGroup: FeatureGroup.DataImport,
    feature: Feature.DataImport,
    resource: {type: 'route', path: '/data-import/contract', component: ContractImportScreen},
  },
  {
    type: 'withoutChildren',
    dictKey: '',
    hide: () => true,
    featureGroup: FeatureGroup.DataImport,
    feature: Feature.DataImport,
    resource: {type: 'route', path: '/data-import/reservation', component: ReservationImportScreen},
  },
  {
    type: 'withoutChildren',
    dictKey: '',
    hide: () => true,
    featureGroup: FeatureGroup.DataImport,
    feature: Feature.DataImport,
    resource: {type: 'route', path: '/data-import/detail', component: WDataImportDetail},
  },
  {
    type: 'withoutChildren',
    dictKey: 'noAuthority',
    hide: () => true,
    resource: {type: 'route', path: '/', component: ErrorDisplayScreen},
    featureGroup: FeatureGroup.AboutBitkey,
    feature: Feature.AboutBitkey,
  },
  {
    type: 'withChildren',
    dictKey: 'setting',
    icon: Icon.Settings,
    groupKey: 'other',
    children: [
      {
        dictKey: 'authority',
        resource: {type: 'route', path: '/authority', component: AuthorityScreen},
        featureGroup: FeatureGroup.Authority,
        feature: Feature.Authority,
      },
      {
        dictKey: 'mailTemplates',
        resource: {type: 'route', path: '/settings/mail-templates', component: WMailTemplateScreen},
        featureGroup: FeatureGroup.V2MailTemplates,
        feature: Feature.V2MailTemplates,
      },
      {
        dictKey: 'linkageSetting',
        resource: {type: 'route', path: '/settings/linkage-setting', component: WLinkageSettingScreen},
        featureGroup: FeatureGroup.LinkageSetting,
        feature: Feature.LinkageSetting,
      },
      {
        dictKey: 'ssoSetting',
        // SSO連携でのログイン画面のURLパースの作り方がよくなくて、
        // URLをログイン画面にするとssoのログイン画面に遷移してしまうので一旦短縮
        resource: {type: 'route', path: '/settings/ss-setting', component: WSSOSettingScreen},
        featureGroup: FeatureGroup.SSOSetting,
        feature: Feature.SSOSetting,
      },
      {
        dictKey: 'organizationSetting',
        resource: {type: 'route', path: '/settings/organization', component: WOrganizationSettingScreen},
        featureGroup: FeatureGroup.Authority,
        feature: Feature.V2OrganizationSetting,
      },
      {
        dictKey: 'notificationSetting',
        resource: {type: 'route', path: '/settings/notification', component: WNotificationSettingScreen},
        featureGroup: FeatureGroup.Authority,
        feature: Feature.NotificationSetting,
      },
      // TODO エリアの予約設定がちゃんとできたら消す
      {
        dictKey: 'reservationSetting',
        resource: {type: 'route', path: '/reservation/reservation-settings', component: ReservationSetting},
        featureGroup: FeatureGroup.ReservationCalendar,
        feature: Feature.ReservationSetting,
      },
      {
        dictKey: 'wReservationUnavailableTimeSettingScreen',
        resource: {
          type: 'route',
          path: '/reservation/reservation-settings/unavailable-time-settings',
          component: WReservationUnavailableTimeSettingScreen,
        },
        featureGroup: FeatureGroup.ReservationCalendar,
        feature: Feature.ReservationSetting,
        hide: () => true,
      },
      {
        dictKey: 'wReservationUnavailableTimeSettingScreen',
        resource: {
          type: 'route',
          path: '/reservation/reservation-settings/unavailable-time-settings/detail',
          component: WReservationUnavailableTimeSettingDetail,
        },
        featureGroup: FeatureGroup.ReservationCalendar,
        feature: Feature.ReservationSetting,
        hide: () => true,
      },
      {
        dictKey: 'receptionSetting',
        resource: {type: 'route', path: '/reception/reception-settings', component: WReceptionSettingScreen},
        featureGroup: FeatureGroup.V2Reception,
        feature: Feature.ReceptionSetting,
      },
      {
        dictKey: 'receptionSettingTemplate',
        resource: {
          type: 'route',
          path: '/reception/reception-setting-template',
          component: WReceptionSettingTemplateScreen,
        },
        featureGroup: FeatureGroup.V2Reception,
        feature: Feature.ReceptionSettingTemplate,
      },
      {
        dictKey: 'attendanceSetting',
        resource: {type: 'route', path: '/settings/attendance-setting', component: WAttendanceSettingScreen},
        featureGroup: FeatureGroup.Attendance,
        feature: Feature.Attendance,
      },
      {
        dictKey: 'fmDashboardSetting',
        resource: {type: 'route', path: '/settings/fm-dashboard', component: FMDashboardSetting},
        featureGroup: FeatureGroup.FMDashboard,
        feature: Feature.FMDashboard,
      },
      {
        dictKey: '',
        hide: () => true,
        resource: {
          type: 'route',
          path: '/settings/fm-dashboard/satisfaction',
          component: FMDashboardSatisfactionSetting,
        },
        featureGroup: FeatureGroup.FMDashboard,
        feature: Feature.FMDashboard,
      },
      {
        dictKey: '',
        hide: () => true,
        resource: {
          type: 'route',
          path: '/settings/fm-dashboard/satisfaction/:satisfactionSettingId',
          component: FMDashboardSatisfactionSetting,
        },
        featureGroup: FeatureGroup.FMDashboard,
        feature: Feature.FMDashboard,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'productSupport',
    icon: lazy(() => import('@mui/icons-material/Help')),
    groupKey: 'other',
    children: [
      {
        dictKey: 'wManual',
        resource: {type: 'route', path: '/manual', component: WManualScreen},
        featureGroup: FeatureGroup.V2Manual,
        feature: Feature.V2Manual,
      },
      {
        dictKey: 'wSupportSite',
        resource: {
          type: 'link',
          url: 'https://support-workhub.notion.site/workhub-367b9ba92b744b30b33bc01bf0464f27',
        },
        icon: lazy(() => import('@/components/figma/icon/WIconExternal')),
        featureGroup: FeatureGroup.V2Manual,
        feature: Feature.V2Manual,
      },
    ],
  },
  {
    type: 'withChildren',
    dictKey: 'aboutWorkhub',
    icon: lazy(() => import('@/components/figma/icon/WIconAboutWorkhub')),
    groupKey: 'other',
    divider: 'top',
    children: [
      {
        dictKey: 'bitkeyInfo',
        resource: {type: 'link', url: 'https://bitkey.co.jp/about/'},
        icon: lazy(() => import('@/components/figma/icon/WIconExternal')),
        featureGroup: FeatureGroup.AboutBitkey,
        feature: Feature.AboutBitkey,
        fontSize: 12,
      },
      {
        dictKey: 'termOfUse',
        resource: {type: 'link', url: 'https://terms.bitkey.co.jp/'},
        icon: lazy(() => import('@/components/figma/icon/WIconExternal')),
        featureGroup: FeatureGroup.AboutBitkey,
        feature: Feature.AboutBitkey,
        fontSize: 12,
      },
      {
        dictKey: 'privacyPolicy',
        resource: {type: 'link', url: 'https://terms.bitkey.co.jp/top/privacy-policy'},
        icon: lazy(() => import('@/components/figma/icon/WIconExternal')),
        featureGroup: FeatureGroup.AboutBitkey,
        feature: Feature.AboutBitkey,
        fontSize: 12,
      },
      {
        dictKey: 'whitePaper',
        resource: {type: 'link', url: 'https://terms.bitkey.co.jp/top/security-whitepaper'},
        icon: lazy(() => import('@/components/figma/icon/WIconExternal')),
        featureGroup: FeatureGroup.AboutBitkey,
        feature: Feature.AboutBitkey,
        fontSize: 12,
      },
    ],
  },
];
