// noinspection JSUnusedGlobalSymbols

import {createIcon} from '../utils.js';

export const Filter = createIcon('Filter', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M9 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2M6.17 5a3.001 3.001 0 0 1 5.66 0H20a1 1 0 1 1 0 2h-8.17a3 3 0 0 1-5.66 0H4a1 1 0 0 1 0-2zM15 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2.83 0a3 3 0 0 1 5.66 0H20a1 1 0 0 1 0 2h-2.17a3 3 0 0 1-5.66 0H4a1 1 0 1 1 0-2zM9 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2.83 0a3 3 0 0 1 5.66 0H20a1 1 0 0 1 0 2h-8.17a3 3 0 0 1-5.66 0H4a1 1 0 1 1 0-2z'
    />
  </svg>
));

export const FilterClear = createIcon('FilterClear', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M9.707 5.293a1 1 0 0 1 .257.44l1.683 1.683A3 3 0 0 0 11.83 7H20a1 1 0 1 0 0-2h-8.17a3 3 0 0 0-4.246-1.647l1.683 1.683a1 1 0 0 1 .44.257m-6.06-.228A1 1 0 0 0 4 7h1.582l4 4H4a1 1 0 0 0 0 2h7.582l4 4H11.83a3.001 3.001 0 0 0-5.66 0H4a1 1 0 0 0 0 2h2.17a3 3 0 0 0 5.66 0h5.752l1.671 1.671a1 1 0 1 0 1.415-1.414L4.708 3.297A1 1 0 0 0 3.292 4.71zM17.83 13q-.076.216-.183.416l-1.683-1.683a1 1 0 0 0-.697-.697l-1.683-1.683A3 3 0 0 1 17.83 11H20a1 1 0 0 1 0 2zM9 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2'
      clipRule='evenodd'
    />
  </svg>
));

export const Map = createIcon('Map', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='m15 5.398-5.35-1.79a2.1 2.1 0 0 0-1.3-.009L3.68 5.101a1 1 0 0 0-.493.35.93.93 0 0 0-.187.56v13.423c0 .67.71 1.139 1.36.89L9 18.602l5.35 1.79c.42.143.88.143 1.3.009l4.67-1.503a1 1 0 0 0 .493-.35.93.93 0 0 0 .187-.558V4.566c0-.67-.71-1.139-1.36-.89zm0 13.204-6-2.019V5.408l6 2.018z'
    />
  </svg>
));

export const Dashboard = createIcon('Dashboard', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M3 12.008a1 1 0 0 0 1 1h5.992a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1zM3 20a1 1 0 0 0 1 1h5.992a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1zm10.008 0a1 1 0 0 0 1 1H20a1 1 0 0 0 1-1v-7.984a1 1 0 0 0-1-1h-5.992a1 1 0 0 0-1 1zm1-17a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1H20a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z'
    />
  </svg>
));

export const HasChild = createIcon('HasChild', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M3 4.008q0-.422.281-.703A1 1 0 0 1 4.008 3h3.984q.422 0 .703.305A.92.92 0 0 1 9 4.008V6a1 1 0 0 1-.305.727.95.95 0 0 1-.703.28H4.008q-.422 0-.727-.28A1.03 1.03 0 0 1 3 6zM6 9q.422 0 .703.305a.92.92 0 0 1 .305.703v1.008H12q.422 0 .703.304a.9.9 0 0 1 .305.68 1 1 0 0 1-.305.727.95.95 0 0 1-.703.28H7.008V18H12q.422 0 .703.305a.92.92 0 0 1 .305.703 1 1 0 0 1-.305.726.95.95 0 0 1-.703.282H6q-.421 0-.727-.282a1.03 1.03 0 0 1-.28-.726v-9q0-.422.28-.703A1 1 0 0 1 6 9m10.008 1.008a1 1 0 0 0-.727.305.95.95 0 0 0-.281.703v1.992q0 .422.281.726.305.282.727.282h3.984a.95.95 0 0 0 .703-.282 1 1 0 0 0 .305-.726v-1.992a.92.92 0 0 0-.305-.703.92.92 0 0 0-.703-.305zM15 18q0-.398.281-.68a1 1 0 0 1 .727-.304h3.984q.422 0 .703.304A.9.9 0 0 1 21 18v2.016q0 .398-.305.703a.95.95 0 0 1-.703.281h-3.984q-.422 0-.727-.281a1 1 0 0 1-.281-.703z'
    />
  </svg>
));

export const List = createIcon('List', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M8.004 7.008q0-.422.281-.703A1 1 0 0 1 9.011 6h9.982q.421 0 .703.305a.92.92 0 0 1 .304.703 1 1 0 0 1-.304.726.95.95 0 0 1-.703.282H9.011q-.421 0-.726-.282a1.03 1.03 0 0 1-.28-.726m0 4.992q0-.399.281-.68a1 1 0 0 1 .726-.304h9.982q.421 0 .703.304A.9.9 0 0 1 20 12a1 1 0 0 1-.304.727.95.95 0 0 1-.703.28H9.011q-.421 0-.726-.28a1.03 1.03 0 0 1-.28-.727m-2.997 4.008a1 1 0 0 0-.726.305.95.95 0 0 0-.281.703q0 .398.281.703.305.28.726.281a.95.95 0 0 0 .702-.281.97.97 0 0 0 .305-.703.92.92 0 0 0-.305-.703.92.92 0 0 0-.702-.305m4.004 0a1 1 0 0 0-.726.305.95.95 0 0 0-.28.703q0 .398.28.703.305.28.726.281h9.982a.95.95 0 0 0 .703-.281.97.97 0 0 0 .304-.703.92.92 0 0 0-.304-.703.92.92 0 0 0-.703-.305zm-4.004-4.992a1 1 0 0 0-.726.304A.93.93 0 0 0 4 12q0 .421.281.727.305.28.726.28a.95.95 0 0 0 .702-.28A1 1 0 0 0 6.014 12a.9.9 0 0 0-.305-.68.92.92 0 0 0-.702-.304m0-5.016a1 1 0 0 0-.726.305.95.95 0 0 0-.281.703q0 .422.281.726.305.282.726.282a.95.95 0 0 0 .702-.282 1 1 0 0 0 .305-.726.92.92 0 0 0-.305-.703A.92.92 0 0 0 5.007 6'
    />
  </svg>
));

export const Unlist = createIcon('Unlist', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M4.711 3.297A1 1 0 0 0 3.297 4.71l1.352 1.352q-.202.074-.368.242A.95.95 0 0 0 4 7.008q0 .422.281.726.305.282.726.282a.95.95 0 0 0 .702-.282 1 1 0 0 0 .242-.368l3.65 3.65h-.59a1 1 0 0 0-.726.304.93.93 0 0 0-.28.68q0 .421.28.727.305.28.726.28h2.583l3 3H9.01a1 1 0 0 0-.726.305.95.95 0 0 0-.28.704q0 .398.28.703.305.28.726.281h7.575l2.71 2.711a1 1 0 0 0 1.415-1.414zm12.54 9.71-1.993-1.991h3.735q.421 0 .703.304A.9.9 0 0 1 20 12a1 1 0 0 1-.304.727.95.95 0 0 1-.703.28zm-4.993-4.991L10.242 6h8.751q.421 0 .703.305a.92.92 0 0 1 .304.703 1 1 0 0 1-.304.726.95.95 0 0 1-.703.282zm-7.977 8.297a1 1 0 0 1 .726-.305q.42 0 .702.305a.92.92 0 0 1 .305.703q0 .398-.305.703a.95.95 0 0 1-.702.281q-.421 0-.726-.281A1 1 0 0 1 4 17.016q0-.423.281-.703m0-4.993a1 1 0 0 1 .726-.304q.42 0 .702.304a.9.9 0 0 1 .305.68 1 1 0 0 1-.305.727.95.95 0 0 1-.702.28q-.421 0-.726-.28A1.03 1.03 0 0 1 4 12q0-.399.281-.68'
      clipRule='evenodd'
    />
  </svg>
));

export const Sort = createIcon('Sort', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <g fill='currentColor'>
      <path d='M7 5.008v14.984q0 .422.281.703a.97.97 0 0 0 .703.305.92.92 0 0 0 .704-.305.92.92 0 0 0 .304-.703V5.008a1 1 0 0 0-.305-.727A.95.95 0 0 0 7.985 4q-.398 0-.703.281A1.03 1.03 0 0 0 7 5.008M15.992 3q-.421 0-.726.281a1.03 1.03 0 0 0-.282.727v14.984q0 .422.282.703a1 1 0 0 0 .726.305.92.92 0 0 0 .703-.305.92.92 0 0 0 .305-.703V4.008a1 1 0 0 0-.305-.727.95.95 0 0 0-.703-.281' />
      <path
        fillRule='evenodd'
        d='M7.293 3.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414L8 5.414 5.707 7.707a1 1 0 0 1-1.414-1.414zM16.707 20.707a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L16 18.586l2.293-2.293a1 1 0 0 1 1.414 1.414z'
        clipRule='evenodd'
      />
    </g>
  </svg>
));

export const BarChartVertical = createIcon('BarChartVertical', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <g fill='currentColor'>
      <path d='M3 2a1 1 0 0 1 1 1v17h17a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1' />
      <path d='M7 13a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1M12 9a1 1 0 0 0-2 0l-.005 8a1 1 0 1 0 2 0zM19 6a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1M16 12a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0z' />
    </g>
  </svg>
));

export const BarChartHorizontal = createIcon('BarChartHorizontal', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <g fill='currentColor'>
      <path d='M4 3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 1 0 0-2H4z' />
      <path d='M7 4a1 1 0 0 0 0 2h13a1 1 0 1 0 0-2zM7 16a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2zM7 8a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2zM7 12a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2z' />
    </g>
  </svg>
));

export const LineChart = createIcon('LineChart', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <g fill='currentColor'>
      <path d='M4 3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 1 0 0-2H4z' />
      <path d='M19.707 9.707a1 1 0 0 0-1.414-1.414L14 12.586l-2.293-2.293a1 1 0 0 0-1.414 0l-4 4a1 1 0 1 0 1.414 1.414L11 12.414l2.293 2.293a1 1 0 0 0 1.414 0z' />
    </g>
  </svg>
));

export const PieChart = createIcon('PieChart', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <g fill='currentColor'>
      <path
        fillRule='evenodd'
        d='M12.293 2.293A1 1 0 0 1 13 2a9 9 0 0 1 9 9 1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V3a1 1 0 0 1 .293-.707M14 4.07V10h5.93A7 7 0 0 0 14 4.07'
        clipRule='evenodd'
      />
      <path d='M20.433 14.003a1 1 0 0 1 .997 1.327 9.998 9.998 0 0 1-19.036-.555A10 10 0 0 1 8.67 2.57a1.001 1.001 0 0 1 .66 1.89 8 8 0 1 0 10.21 10.21 1 1 0 0 1 .893-.667' />
    </g>
  </svg>
));
