import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useMutation, useQuery, UseQueryResult} from '@tanstack/react-query';
import {createFileRoute} from '@tanstack/react-router';
import {AxiosError} from 'axios';
import range from 'lodash/range';
import {FC, memo, useCallback, useMemo, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import {searchSatisfactionApi, SearchSatisfactionResponse} from '@/api-call/workhub-core/searchSatisfactionApi';
import {
  searchSatisfactionSettingApi,
  SearchSatisfactionSettingResponse,
} from '@/api-call/workhub-core/searchSatisfactionSettingApi';
import {updateSatisfactionApi} from '@/api-call/workhub-core/updateSatisfactionApi';
import useDict from '@/common/hooks/useDict';
import {useLocaleIsJp} from '@/common/hooks/useLocaleIsJp';
import useLocaleName from '@/common/hooks/useLocaleName';
import {jpDate} from '@/common/jpDate';
import {Locale} from '@/common/redux/state-types/localeStateType';
import {core as CoreColor, WHColor} from '@/common/styles/whColor';
import {WHFont, WHFontCss} from '@/common/styles/whFont';
import WButton from '@/components/button/WButton';
import WIconAreaTypeSpace from '@/components/figma/icon/areaType/WIconAreaTypeSpace';
import WIconCalendarDaily from '@/components/figma/icon/WIconCalendarDaily';
import WIconClock from '@/components/figma/icon/WIconClock';
import WIconRatingStar from '@/components/figma/icon/WIconRatingStar';
import WTextArea from '@/components/figma/input/WTextArea';
import WorkhubLogoText from '@/components/icon/WorkhubLogoText';
import {
  LoadingAreaBase,
  LoadingSkeltonAnimation,
} from '@/components/loading-skelton/common-style/LoadingSkeltonCommonStyle';
import {QuestionnaireItemEdit} from '@/wscreens/fm-dashboard-setting/satisfaction/view/contents/items/QuestionnaireItemEdit';

type Params = {
  satisfactionId: string;
};

export const Route = createFileRoute('/_authorized/_mobile/form/satisfaction/$satisfactionId')({
  component: RouteComponent,
  validateSearch: params => params as Params, // TODO: バリデーション
});

const Main = styled.main`
  width: 100%;
  min-height: 100%;
  background-color: var(--surface-neutral-low);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Header = styled.header`
  flex: 0 0 130px;
  background-color: var(--surface-brand-primary);
  padding: 10px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Heading = styled.h1`
  ${WHFontCss.titleSmall}
  color: var(--text-neutral-primary);
  text-align: center;
  margin: 0 0 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 40px 0 0;
`;

const StyledTextArea = styled(WTextArea)({
  ...WHFont.bodyLarge,
  padding: '8px',
  boxSizing: 'border-box' as const,
  '&::placeholder': {
    color: WHColor.text.neutralDisabled,
  },
});

function RouteComponent() {
  const {satisfactionId} = Route.useParams();

  const {data, isPending, isError} = useQuery({
    queryKey: ['satisfaction', satisfactionId] as const,
    queryFn: async ({queryKey}) => {
      const [, id] = queryKey;
      const {data} = await searchSatisfactionApi({
        body: {
          ids: [id],
        },
      });

      return data.data;
    },
    select: data => {
      return data[0];
    },
  });

  const {data: questionnaires} = useQuery({
    queryKey: ['satisfactionSetting', data?.satisfactionSetting.id],
    queryFn: async ({queryKey}) => {
      const [, id] = queryKey;
      const {data} = await searchSatisfactionSettingApi({
        body: {
          ids: [id as string],
        },
      });

      return data.data;
    },
    select: data => {
      return data[0].questionnaires;
    },
    enabled: !!data?.satisfactionSetting.id,
  });

  return (
    <Main>
      <Header>
        <LogoWrapper>
          <WorkhubLogoText color={WHColor.text.neutralWhitePrimary} />
        </LogoWrapper>
      </Header>
      <FormSatisfactionContent
        data={data}
        questionnaires={questionnaires}
        isLoading={isPending}
        isError={isError}
        satisfactionId={satisfactionId}
      />
    </Main>
  );
}

const ContentWrapper = styled.section`
  padding: 40px 16px 50px;
  width: 100%;
  box-sizing: border-box;
`;

const ContentParagraph = styled.p`
  ${WHFontCss.bodyMedium}
  text-align: center;
  color: var(--text-neutral-disabled);
`;

const Picture = styled.picture`
  display: block;
  margin: 40px;

  & > img,
  & > source {
    display: block;
    width: 100%;
    height: auto;
  }
`;

type QueryResult = UseQueryResult<SearchSatisfactionResponse['data'][number], AxiosError>;

type FormSatisfactionContentProps = Pick<QueryResult, 'isLoading' | 'data' | 'isError'> & {
  satisfactionId: string;
} & {
  questionnaires: SearchSatisfactionSettingResponse['data'][number]['questionnaires'];
};

const dictDef = {
  headErrorSurvay: {
    default: {
      default: 'アンケートを取得できませんでした',
      [Locale.en_US]: 'Could not get questionnaire.',
    },
  },
  headAlreadyAnswered: {
    default: {
      default: 'すでに回答済みのアンケートです',
      [Locale.en_US]: 'Questionnaire that has already been answered.',
    },
  },
  paragraphFirstAlreadyAnswered: {
    default: {
      default: 'このアンケートに回答できるのは1回のみです',
      [Locale.en_US]: 'You can only take this questionnaire once.',
    },
  },
  paragraphSecondAlreadyAnswered: {
    default: {
      default: 'ご回答ありがとうございました',
      [Locale.en_US]: 'Thank you for your answer.',
    },
  },
  headExpired: {
    default: {
      default: 'アンケートの回答期限が切れてます',
      [Locale.en_US]: 'Questionnaire deadline has expired',
    },
  },
  phraseFirstExpired: {
    default: {
      default: 'アンケートの回答受け付け',
      [Locale.en_US]: 'Questionnaire response acceptance has ended',
    },
  },
  phraseSecondExpired: {
    default: {
      default: 'は終了しました',
      [Locale.en_US]: '',
    },
  },
  headForm: {
    default: {
      default: 'アンケートが届いています',
      [Locale.en_US]: 'A questionnaire has been sent to you.',
    },
  },
};

const FormSatisfactionContent = memo<FormSatisfactionContentProps>(
  ({data, isLoading, isError, satisfactionId, questionnaires}) => {
    const useStyles = makeStyles()({
      headingLoadingAreaBase: {
        height: '1rem',
        width: '100%',
      },
      loadingSkeltonAnimation: {
        height: '100%',
        width: '100%',
      },
    });

    const now = jpDate();
    const {classes} = useStyles();

    const dict = useDict(dictDef);
    const isExpired = useMemo(() => {
      return data?.satisfactionSetting.validTo
        ? !now.isBetween(
            jpDate(data.satisfactionSetting.validFrom),
            jpDate(data.satisfactionSetting.validTo),
            'day',
            '[]'
          )
        : now.isBefore(jpDate(data?.satisfactionSetting.validFrom));
    }, [now, data?.satisfactionSetting.validFrom, data?.satisfactionSetting.validTo]);
    if (isLoading) {
      return (
        <ContentWrapper>
          <Heading>
            <LoadingAreaBase className={classes.headingLoadingAreaBase}>
              <LoadingSkeltonAnimation className={classes.loadingSkeltonAnimation} />
            </LoadingAreaBase>
          </Heading>
          <LoadingAreaBase css={{height: '168px', margin: '8px 48px 48px', borderRadius: '8px'}}>
            <LoadingSkeltonAnimation className={classes.loadingSkeltonAnimation} />
          </LoadingAreaBase>

          <LoadingAreaBase css={{height: '20px', margin: '0'}}>
            <LoadingSkeltonAnimation className={classes.loadingSkeltonAnimation} />
          </LoadingAreaBase>
          <LoadingAreaBase css={{height: '96px', margin: '16px 0 0'}}>
            <LoadingSkeltonAnimation className={classes.loadingSkeltonAnimation} />
          </LoadingAreaBase>
        </ContentWrapper>
      );
    }
    if (isError || !data) {
      return (
        <ContentWrapper>
          <Heading>{dict.headErrorSurvay}</Heading>
          <Picture>
            <source width='264' height='293' src='/image/form/image-satisfaction-expired.png 1x' />
            <source width='264' height='293' src='/image/form/image-satisfaction-expired@2x.png 2x' />
            <img width='264' height='293' src='/image/form/image-satisfaction-expired.png' aria-hidden='true' alt='' />
          </Picture>
        </ContentWrapper>
      );
    }

    // 回答済みかどうかをansweredAtがあるかどうかで判定する。
    // answeredAtがある場合はすでに回答されているため。
    if (data.answeredAt) {
      return (
        <ContentWrapper>
          <Heading>{dict.headAlreadyAnswered}</Heading>
          <ContentParagraph>
            {dict.paragraphFirstAlreadyAnswered}
            <br />
            {dict.paragraphSecondAlreadyAnswered}
          </ContentParagraph>
          <Picture>
            <source width='264' height='293' src='/image/form/image-satisfaction-already.png 1x' />
            <source width='264' height='293' src='/image/form/image-satisfaction-already@2x.png 2x' />
            <img width='264' height='293' src='/image/form/image-satisfaction-expired.png' aria-hidden='true' alt='' />
          </Picture>
        </ContentWrapper>
      );
    } else if (isExpired) {
      return (
        <ContentWrapper>
          <Heading>{dict.headExpired}</Heading>
          <ContentParagraph>
            {dict.phraseFirstExpired}
            <br />
            {dict.phraseSecondExpired}
          </ContentParagraph>
          <Picture>
            <source width='264' height='293' src='/image/form/image-satisfaction-expired.png 1x' />
            <source width='264' height='293' src='/image/form/image-satisfaction-expired@2x.png 2x' />
            <img width='264' height='293' src='/image/form/image-satisfaction-expired.png' aria-hidden='true' alt='' />
          </Picture>
        </ContentWrapper>
      );
    }
    return (
      <ContentWrapper>
        <Heading css={{margin: '0 0 24px'}}>{dict.headForm}</Heading>
        <Form
          space={data?.space}
          satisfactionSetting={data.satisfactionSetting}
          reservation={data.reservation}
          satisfactionId={satisfactionId}
          questionnaires={questionnaires}
        />
      </ContentWrapper>
    );
  }
);

FormSatisfactionContent.displayName = 'FormSatisfactionEnqueteContent';

const FormSatisfactionContentAbstract = memo<{
  space: SearchSatisfactionResponse['data'][number]['space'];
  reservation: SearchSatisfactionResponse['data'][number]['reservation'];
}>(({space, reservation}) => {
  const spaceName = useLocaleName(space);
  const isJp = useLocaleIsJp();

  const from = useMemo(() => {
    const from = jpDate(reservation?.baseCondition.from);
    return from.isValid() ? from.format(isJp ? 'YYYY年MM月DD日 HH:mm' : 'YYYY/MM/DD  HH:mm') : '';
  }, [reservation?.baseCondition.from, isJp]);

  const to = useMemo(() => {
    const from = jpDate(reservation?.baseCondition.from);
    const to = jpDate(reservation?.baseCondition.to);
    if (to.isSame(from, 'day')) {
      return to.format('- HH:mm');
    }
    if (to.isSame(from, 'year')) {
      return to.format(isJp ? '- MM月DD日 HH:mm' : '- MM/DD  HH:mm');
    }
    return to.format(isJp ? '- YYYY年MM月DD日 HH:mm' : '- YYYY/MM/DD  HH:mm');
  }, [reservation?.baseCondition.from, reservation?.baseCondition.to, isJp]);

  return (
    <div
      css={{
        color: WHColor.text.neutralPrimary,
        padding: '8px',
        backgroundColor: WHColor.surface.neutralMiddle,
        borderRadius: '8px',
        margin: '0 0 40px',
      }}
    >
      <p css={{display: 'flex', alignItems: 'center', gap: '8px'}}>
        <span css={{flex: '0 0 auto'}}>
          <WIconAreaTypeSpace />
        </span>
        <span css={{...WHFont.bodyLarge}}>{spaceName}</span>
      </p>

      {reservation ? (
        <>
          {reservation.title ? (
            <span css={{display: 'flex', alignItems: 'center', gap: '8px'}}>
              <span css={{flex: '0 0 auto'}}>{<WIconCalendarDaily color={WHColor.object.brandPrimary} />}</span>
              <span css={{...WHFont.bodyLarge}}>{reservation.title}</span>
            </span>
          ) : null}
          <span css={{display: 'flex', alignItems: 'center', gap: '8px'}}>
            <span css={{flex: '0 0 auto'}}>
              <WIconClock color={WHColor.object.brandPrimary} />
            </span>
            <span css={{display: 'flex', flexWrap: 'wrap', ...WHFont.bodyLarge}}>
              <span>{from}</span>
              <span>&ensp;{to}</span>
            </span>
          </span>
        </>
      ) : null}
    </div>
  );
});
FormSatisfactionContentAbstract.displayName = 'FormSatisfactionEnqueteAbstract';

const ratingWrapperStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

type RatingProps = {
  value?: number;
  onChange?: (value: number) => void;
};
const RATING_RANGE = range(1, 11);
const Rating = memo<RatingProps>(function Rating({value, onChange}) {
  const judgeRatingColor = useCallback(
    v => {
      return v <= (value ?? 0) ? CoreColor.yellow200 : WHColor.state.disabled;
    },
    [value]
  );

  return (
    <>
      <input name='score' value={value} hidden />
      <div css={ratingWrapperStyle}>
        {RATING_RANGE.map(v => (
          <WIconRatingStar
            key={`Rating-${v}`}
            size={28}
            color={judgeRatingColor(v)}
            onClick={() => onChange && onChange(v)}
          />
        ))}
      </div>
    </>
  );
});

const formDict = {
  placeholder: {
    default: {
      default: 'ご意見やご要望があれば、お書きください',
      [Locale.en_US]: 'If you have any feedback, please let us know.',
    },
  },
  question: {
    default: {
      default: '満足度を教えてください',
      [Locale.en_US]: ' Let us know your level of satisfaction',
    },
  },
  send: {
    default: {
      default: 'この内容で回答する',
      [Locale.en_US]: 'Send',
    },
  },
};

const Form: FC<
  Pick<SearchSatisfactionResponse['data'][number], 'space' | 'reservation' | 'satisfactionSetting'> & {
    satisfactionId: string;
  } & {
    questionnaires: SearchSatisfactionSettingResponse['data'][number]['questionnaires'];
  }
> = ({satisfactionId, space, reservation, satisfactionSetting, questionnaires}) => {
  const dict = useDict(formDict);
  const [score, setScore] = useState(0);
  const [comment, setComment] = useState('');
  const [questionToAnswerIds, setQuestionToAnswerIds] = useState<Record<string, string>>({});

  const navigate = Route.useNavigate();
  const {mutate, isPending} = useMutation({
    mutationFn: async () => {
      return await updateSatisfactionApi({
        body: {
          score,
          comment,
          questionnaires: Object.entries(questionToAnswerIds).map(([questionnaireId, radioId]) => ({
            questionnaireId,
            optionIds: [radioId],
          })),
        },
        paths: {satisfactionId},
      });
    },
    onSuccess: () => {
      navigate({to: '/form/satisfaction/complete', state: state => ({...state, score})});
    },
  });
  const disabled = useMemo(() => !score, [score]);

  const onChange = useCallback<(value: number) => void>(value => {
    setScore(value);
  }, []);

  const onChangeQuestionnaire = useCallback(
    (questionnaireId: string) => (radioId: string) => {
      setQuestionToAnswerIds(prev => {
        return {...prev, [questionnaireId]: radioId};
      });
    },
    []
  );

  return (
    <>
      <FormSatisfactionContentAbstract space={space} reservation={reservation} />
      <p css={{margin: '0 0 16px', textAlign: 'center', ...WHFont.bodyLarge, color: WHColor.text.neutralPrimary}}>
        {satisfactionSetting?.description ?? dict.question}
      </p>

      <Rating value={score} onChange={onChange} />
      <div css={{margin: '16px 0 0'}}>
        <StyledTextArea
          value={comment}
          rows={3}
          placeholder={dict.placeholder}
          onChange={e => {
            setComment(e.target.value);
          }}
        />
      </div>

      {questionnaires?.map(questionnaire => (
        <div css={{marginTop: 40}} key={questionnaire.id}>
          <QuestionnaireItemEdit
            questionnaireName={questionnaire.questionnaireText}
            choices={
              questionnaire.options?.map(option => ({
                id: option.id,
                name: option.optionText,
              })) ?? []
            }
            selectId={questionToAnswerIds[questionnaire.id] ?? ''}
            onChange={onChangeQuestionnaire(questionnaire.id)}
          />
        </div>
      ))}

      <ButtonWrapper css={WHFont.bodyLarge}>
        <WButton
          backgroundcolor={WHColor.surface.accentDefault}
          running={isPending}
          disabled={disabled || isPending}
          fontSize={16}
          onClick={() => {
            mutate();
          }}
          height={'56px'}
          color='primary'
          label={dict.send}
        />
      </ButtonWrapper>
    </>
  );
};
