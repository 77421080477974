// noinspection JSUnusedGlobalSymbols

import {createIcon} from '../utils.js';

export const Left = createIcon('Left', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M8.549 18.377a.97.97 0 0 0-.28.855q.047.499.443.737.559.333 1.142.594.582.237 1.211.404a.9.9 0 0 0 .839-.19 1 1 0 0 0 .372-.808q0-.357-.186-.618a.88.88 0 0 0-.536-.357 9 9 0 0 1-.908-.332 6 6 0 0 1-.886-.452 1 1 0 0 0-.302-.095.8.8 0 0 0-.327-.024 1 1 0 0 0-.302.095.9.9 0 0 0-.28.19m12.44-1.617q.327-.546.583-1.14.232-.594.396-1.236a.95.95 0 0 0-.187-.856q-.325-.38-.792-.38h-.023a.88.88 0 0 0-.582.214.9.9 0 0 0-.373.523 7 7 0 0 1-.303.974 5.5 5.5 0 0 1-.443.856 1 1 0 0 0-.116.332 1 1 0 0 0 0 .31q.023.165.093.332.07.142.186.262.35.331.839.285.465-.047.722-.476m-6.71 3.209q0 .498.397.808a.9.9 0 0 0 .839.19q.629-.166 1.211-.404a9.5 9.5 0 0 0 1.118-.594.94.94 0 0 0 .466-.737 1.07 1.07 0 0 0-.28-.855h-.023a.981.981 0 0 0-1.188-.166q-.42.26-.862.45-.466.19-.955.31-.303.119-.513.38a.96.96 0 0 0-.21.618M8.037 4.687a9.2 9.2 0 0 0-1.56 1.474 8.6 8.6 0 0 0-1.188 1.782 9.4 9.4 0 0 0-.746 2.044 9 9 0 0 0-.256 2.14h-1.77q-.35 0-.467.308-.14.309.117.547l2.772 2.852a.47.47 0 0 0 .35.142.47.47 0 0 0 .349-.142l2.772-2.829q.233-.261.117-.57-.14-.309-.466-.309H6.313q0-1.663.745-3.232.746-1.569 2.283-2.638a6 6 0 0 1 1.91-.927 6.6 6.6 0 0 1 2.074-.285q1.024 0 2.05.333a6.1 6.1 0 0 1 1.887.95 6.9 6.9 0 0 1 1.724 1.783q.675 1.046.978 2.257a.9.9 0 0 0 .35.547q.256.19.582.19.489 0 .792-.38a.95.95 0 0 0 .187-.856 9 9 0 0 0-1.189-2.78 9.4 9.4 0 0 0-2.19-2.353 8.7 8.7 0 0 0-2.492-1.283A8.6 8.6 0 0 0 13.278 3q-1.374 0-2.726.404-1.35.428-2.516 1.283'
    />
  </svg>
));

export const Right = createIcon('Right', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M15.451 18.377a.97.97 0 0 1 .28.855q-.047.499-.443.737-.559.333-1.142.594a10 10 0 0 1-1.211.404.9.9 0 0 1-.839-.19 1 1 0 0 1-.372-.808q0-.357.186-.618a.88.88 0 0 1 .536-.357q.465-.143.908-.332.466-.19.886-.452a1 1 0 0 1 .303-.095.8.8 0 0 1 .326-.024q.163.024.303.095a.9.9 0 0 1 .279.19M3.011 16.76a10 10 0 0 1-.583-1.14 10 10 0 0 1-.396-1.236.95.95 0 0 1 .187-.856q.326-.38.792-.38h.023q.327 0 .583.214.279.19.372.523.117.498.303.974.186.451.443.856a1 1 0 0 1 .116.332q.024.142 0 .31-.022.165-.093.332a1 1 0 0 1-.186.262 1.02 1.02 0 0 1-.839.285q-.465-.047-.722-.476m6.71 3.209a.98.98 0 0 1-.397.808.9.9 0 0 1-.839.19 10 10 0 0 1-1.211-.404 9.5 9.5 0 0 1-1.118-.594.94.94 0 0 1-.466-.737 1.07 1.07 0 0 1 .28-.855h.023a.981.981 0 0 1 1.188-.166q.42.26.862.45.465.19.955.31.303.119.512.38t.21.618m6.243-15.282a9.2 9.2 0 0 1 1.56 1.474 8.6 8.6 0 0 1 1.188 1.782 9.45 9.45 0 0 1 1.002 4.183h1.77q.35 0 .467.309.14.309-.117.547l-2.772 2.852a.47.47 0 0 1-.35.142.47.47 0 0 1-.349-.142l-2.772-2.829q-.232-.261-.117-.57.14-.309.466-.309h1.747a7.45 7.45 0 0 0-.745-3.232q-.745-1.569-2.283-2.638a6 6 0 0 0-1.91-.927 6.6 6.6 0 0 0-2.074-.285q-1.024 0-2.05.333a6.1 6.1 0 0 0-1.887.95A6.9 6.9 0 0 0 5.014 8.11a7.5 7.5 0 0 0-.978 2.257.9.9 0 0 1-.35.547.95.95 0 0 1-.582.19.96.96 0 0 1-.792-.38.95.95 0 0 1-.186-.856 9 9 0 0 1 1.188-2.78 9.4 9.4 0 0 1 2.19-2.353 8.7 8.7 0 0 1 2.492-1.283A8.6 8.6 0 0 1 10.722 3q1.374 0 2.726.404 1.35.428 2.515 1.283'
    />
  </svg>
));
