// noinspection JSUnusedGlobalSymbols

import {createIcon} from '../utils.js';

export const Up = createIcon('Up', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M11.173 8.434A.94.94 0 0 1 12 8q.536 0 .827.434l4.022 6.222q.29.434.022.89-.267.453-.826.454h-8.09q-.558 0-.826-.455-.269-.455.022-.889z'
    />
  </svg>
));

export const Right = createIcon('Right', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M16.566 11.173Q17 11.464 17 12a.94.94 0 0 1-.434.827l-6.222 4.022q-.434.29-.89.022-.453-.267-.454-.826v-8.09q0-.558.455-.826.455-.269.889.022z'
    />
  </svg>
));

export const Down = createIcon('Down', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M12.827 15.586Q12.536 16 12 16t-.827-.415L7.151 9.348q-.29-.455-.022-.891Q7.397 8 7.955 8h8.09q.558 0 .826.456.27.435-.022.891z'
    />
  </svg>
));

export const Left = createIcon('Left', (props, ref) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' aria-hidden={true} ref={ref} {...props}>
    <path
      fill='currentColor'
      d='M7.415 12.827Q7 12.536 7 12t.415-.827l6.238-4.022q.455-.29.891-.022.456.268.456.826v8.09q0 .558-.456.826-.435.27-.891-.022z'
    />
  </svg>
));
